<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32" height="32"
      alt="Close Icon">
    <div class="modal-header">
      <h4 class="modal-heading">{{context.title}}</h4>
    </div>
    <div class="modal-body">
      <div class="content">
        <p class="title-bottom">You are about to swap to eSIM</p>
        <p class="bold-bottom"><b>({{context.iccid}})</b></p>
        <p class="title-bottom">On Phone Number:</p>
        <p><b>{{context.mdn}}</b></p>
        <button class="button primary" (click)="confirm()" aria-label="proceed">Proceed</button>
      </div>
    </div>
  </div>
</div>