/**
 *
 * Add the app configurations as constants here and export them to be used on
 * the app module.
 *
 */
export const ApplicationStoreLinks = {
    FREEUP_TALK: {
        ANDROID: 'https://play.google.com/store/apps/details?id=com.freeup.freeupmobile.android',
        IOS: 'https://itunes.apple.com/us/app/freeup-talk/id1256515409'
    },
    FREEUP_REWARDS: {
        ANDROID: 'https://play.google.com/store/apps/details?id=com.billaway.freeuprewards&hl=en',
        IOS: 'https://itunes.apple.com/us/app/freeup-mobile-rewards/id1334803998?ls=1&mt=8'
    },
};
export const PASSWORD_PATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,12}$';
export const NAME_PATTERN = '^[A-Za-z]+[ \t]?[A-Za-z- ]+?[ \t]*$';
export const POSTAL_PATTERN = '^\d{5}(-\d{4})?$';
export const NUMBERS_ONLY_PATTERN = '^[0-9]*$';
export const EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
export const Phone_PATTERN = /^(\+1\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const MailChimpConfig = {
    MAIL_CHIMP_ENDPOINT: 'https://freeupmobile.us16.list-manage.com/subscribe/post',
    MAIL_CHIMP_USER_ID: '95ad197ff4dd0d7b7b4f9068a',
    DEALERS_FORM_ID: '35278a1bbf'
};
export const ExternalLinks = {
    BILLAWAY: 'https://freeup.billaway.com'
};
export const ATT = 'att';
export const TMO = 'tmo';
export const SIM = 'sim';
export const ESIM = 'esim';
export const PHYSICAL = 'physical';
export const PARAM_ERROR_MESSAGES = {
    ADDRESS: 'The address you selected is invalid. Please try another one.',
    IMEI: 'The device serial number does not seem to be valid. Please try another one.',
    NETWORK_MISMATCH: 'Changing a device might impact your service, please contact customer care to ensure that your changes are handled correct.',
    NO_COVERAGE: 'The address you entered doesn\'t have sufficient coverage on our network at this time. Please try another address.',
    GENERIC: 'Something went wrong while processing your request. Please refresh the page and try again.'
};
export const SKIP_DEVICE_ATT = { network: 'att', networkType: 'LTE', skuIdentifier: 'G', skuNumber: 'SIMFRP4GLTE' };
export const SKIP_DEVICE_ATT_ESIM = { network: 'att', networkType: 'LTE', skuIdentifier: 'GE', skuNumber: 'ESIMFRP4GLTE', verified: true };
export const SKIP_DEVICE_TMO = { network: 'tmo', networkType: 'LTE', skuIdentifier: 'T', skuNumber: 'SIMFRPTMO4GLTE', verified: true }
export const SKIP_DEVICE_TMO_ESIM = { network: 'tmo', networkType: 'LTE', skuIdentifier: 'TE', skuNumber: 'ESIMFRPTMO4GLTE', verified: true };
export const CAPTCHA_BOT_ERROR_CODE = 466;
export const BRAND = 'Freeup Mobile';