import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BUILD_VERSION, ENV_FIREBASE_CONFIG, INVISIBLE_CAPTCHA_ID } from '../../environments/environment';
import { SUPPORT_ROUTE_URLS, SHOP_ROUTE_URLS, ACTIVATION_ROUTE_URLS, ACCOUNT_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS } from '../../app/app.routes.names';
import { TERMS_AND_CONDITIONS_ROUTE_URLS } from '../../app/support/terms-and-conditions/terms-and-conditions.routesName';
import { INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS } from '../../app/support/int-calling-terms-and-conditions/int-calling-terms-and-conditions.routesName';
import { InvisibleRecaptchaComponent } from '@widgets/invisible-recaptcha/invisible-recaptcha.component';
import { AppState } from '@app/app.service';

@Component({
  selector: 'app-footer-main-navbar',
  templateUrl: './footer-main-navbar.component.html',
  styleUrls: ['./footer-main-navbar.component.scss']
})
export class FooterMainNavbarComponent implements OnInit {
  @ViewChild('invisibleReCaptcha') invisibleReCaptcha: InvisibleRecaptchaComponent;
  public SITE_ID = INVISIBLE_CAPTCHA_ID;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public ACTIVATION_ROUTE_URLS = ACTIVATION_ROUTE_URLS;
  public TERMS_AND_CONDITIONS_ROUTE_URLS = TERMS_AND_CONDITIONS_ROUTE_URLS;
  public INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS = INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS;
  public showAccount: boolean;
  public showShop: boolean;
  public showSupport: boolean;
  public showTerms: boolean;
  public showPrivacy: boolean;
  public showIntTerms: boolean;
  public showDealers: boolean;
  public BUILD_VERSION = BUILD_VERSION;
  public BUILD_DATE = new Date();
  public FIREBASE_CONF = ENV_FIREBASE_CONFIG;
  public openedMenu: string;
  public innerWidth: any;
  public captchaResponse: string;
  public captchaValid = false;
  public isSeconCaptcha = false;

  private MOBILE_SCREEN_WIDTH = 1000;

  constructor(private appState: AppState) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.appState.resetAndExecuteCaptcha.subscribe(response => {
      if (!!response && response?.action) {
        this.invisibleReCaptcha.resetReCaptcha();
        this.invisibleReCaptcha.execute(response?.action);
        this.isSeconCaptcha = false;
      }
    });
    this.appState.resetAndExecuteSecondCaptcha.subscribe(response => {
      if (!!response && response?.action) {
        this.invisibleReCaptcha.resetReCaptcha();
        this.invisibleReCaptcha.execute(response?.action);
        this.isSeconCaptcha = true;
      }
    });
  }
  public resolvedCaptcha(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
    this.captchaValid = !!captchaResponse;
    if(!this.isSeconCaptcha) {
      this.appState.resolvedCaptchaSubject.next(captchaResponse);
    } else {
      this.appState.resolvedSecondCaptchaSubject.next(captchaResponse);
    }
  }
  public toggleMenu(name: string) {
    if (this.innerWidth < 1300) {
      this.openedMenu = this.openedMenu == name ? null : name;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1299) {
      this.openedMenu = null;
    }
  }

}
