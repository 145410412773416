import { EnvironmentProviders, ErrorHandler, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MailChimpConfig } from './app.config';
import { AppState } from './app.service';
import { ENDPOINT_URL, ENV_FIREBASE_CONFIG, BFF_ENDPOINT_URL, ZMP_FREEUP_BFF_ENDPOINT_URL, GOOGLE_CLIENT_ID, environment } from '../environments/environment';
import { AuthModule, ErrorHandlerService } from '@ztarmobile/zwp-services-auth';
import { Metadata, ZwpConfig } from '@ztarmobile/zwp-service';
import { UtilModule } from '@ztarmobile/zwp-services-util';
import { BackendModule } from '@ztarmobile/zwp-service-backend';
import { SwiperModule } from 'swiper/angular';
import { ToastrModule } from 'ngx-toastr';
import 'leaflet';
import 'leaflet.vectorgrid';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { HomeComponent } from './home/home.component';
import { TelecomsServicesModule } from '../providers/providers';
import { WidgetsModule } from '../widgets/widgets.module';
import { ModalsModule } from '../modals/modal.module';
import { MetaService } from '../services/meta-service.service';
import { PhonesService } from '../services/phones.service';
import { ContentfulService } from '../services/contentful.service';
import { ModalHelper } from '../services/modal-helper.service';
import { ToastrHelperService } from '../services/toast-helper.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { CoreModule } from '@ztarmobile/zwp-service';
import { BackendV2Module } from '@ztarmobile/zwp-service-backend-v2';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BringPhoneComponent } from './bring-phone/bring-phone.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { initializeApp } from 'firebase/app';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './http-interceptor';

const metadata: Metadata = {
  author: 'ZtarMobile',
  keywords: 'FreeUp Mobile, Mobile Plans, 5G LTE network, Nationwide 5G Coverage, More data, Bigger savings',
  description: 'FreeUP runs on the largest and fastest 5G LTE network with no speed restrictions. Pick a plan and get Free SIM card & shipping.'
};
const zwpConfig: ZwpConfig = {
  endpointUrl: `${ENDPOINT_URL || ''}`,
  mailChimpConfig: MailChimpConfig,
  BffEndPointURL: `${BFF_ENDPOINT_URL}`,
  consoleErrorReporting: true,
  firebaseAppConfig: ENV_FIREBASE_CONFIG,
  metadata,
  ZMP_G2G_BFF_ENDPOINT_URL: ZMP_FREEUP_BFF_ENDPOINT_URL
};

const app = initializeApp(ENV_FIREBASE_CONFIG);

const googleLoginOptions: Object = {
  scope: 'profile email'
};
const isProduction = environment.production;

const providers: (Provider | EnvironmentProviders)[] = [AppState, MetaService, PhonesService, ContentfulService, ToastrHelperService, ModalHelper,
  { provide: ErrorHandler, useClass: ErrorHandlerService }];
if (!isProduction) {
  providers.push(
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            GOOGLE_CLIENT_ID, googleLoginOptions
          )
        }
      ],
      onError: (error) => {
        console.error(error);
      }
    } as SocialAuthServiceConfig
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  })
}
const imports: (any[] | Type<any> | ModuleWithProviders<{}>)[] = [
  AngularFireModule.initializeApp(ENV_FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    TelecomsServicesModule,
    NgxPageScrollCoreModule.forRoot({ duration: 200 }),
    ToastrModule.forRoot(),
    CoreModule.forRoot(zwpConfig),
    BackendModule,
    BackendV2Module,
    AuthModule,
    UtilModule,
    WidgetsModule,
    SwiperModule,
    ModalsModule,
    AppRoutingModule,
    MatDialogModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule
];
  if (!isProduction) {
    imports.push(SocialLoginModule,GoogleSigninButtonModule);
  }
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    BringPhoneComponent
  ],
  imports: imports,
  exports: [],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }
