import { Injectable } from '@angular/core';
import { IUserPlan, IFirebasePaymentMethod, IFirebaseAddress } from '@ztarmobile/zwp-service-backend';
import { IUser } from '@ztarmobile/zwp-services-auth';
import { ManageAddressesModalComponent } from '../modals/manage-addresses-modal/manage-addresses-modal.component';
import { ManagePaymentModalContextComponent } from '../modals/manage-payment-modal-context/manage-payment-modal-context.component';
import { ConfirmMessageModalComponent } from '../modals/confirm-message-modal/confirm-message-modal.component';
import { ConfirmPasswordModalComponent } from '../modals/confirm-password-modal/confirm-password-modal.component';
import { InformationMessageModalComponent } from '../modals/information-message-modal/information-message-modal.component';
import { AddActivatedNumberModalComponent } from '../modals/add-activated-number-modal/add-activated-number-modal.component';
import { InputModalComponent } from '../modals/input-modal/input-modal.component';
import { RoutingModalComponent } from '../modals/routing-modal/routing-modal.component';
import { EditCcModalComponent } from '../modals/edit-cc-modal/edit-cc-modal.component';
import { SelectCCModalComponent } from '../modals/select-payment-modal/select-payment-modal.component';
import { ShippingAddressModalComponent } from '../modals/shipping-address-modal/shipping-address-modal.component';
import { SimReplacementModalComponent } from '../modals/sim-replacement-modal/sim-replacement-modal.component';
import { FiveGModalComponent } from 'src/modals/fiveg-modal/fiveg-modal.component';
import { PhoneNotImpactedModalComponent } from 'src/modals/phone-not-impacted-modal/phone-not-impacted-modal.component';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertSecurityModalComponent } from 'src/modals/alert-security-modal/alert-security-modal.component';
import { IosDataSetupModalComponent } from 'src/modals/ios-data-setup-modal/ios-data-setup-modal.component';
import { CompatibilitySkipModalComponent } from 'src/modals/skip-device-modal/skip-device-modal.component';
import { eSimReplacementModalComponent } from 'src/modals/esim-replacement-modal/esim-replacement-modal.component';
import { IShipmentTracking } from '@ztarmobile/zwp-service-backend-v2';
import { TrackingModalComponent } from 'src/modals/tracking-details-modal/tracking-details-modal.component';
import { successModalComponent } from 'src/modals/success-modal/success-modal.component';
import { InstractionsFiveGIos17ModalComponent } from 'src/modals/instractions-five-g-ios17-modal/instractions-five-g-ios17-modal.component';
import { GoogleLoginComponent } from 'src/modals/google-login/google-login.component';
import { RecaptchaVerificationComponent } from 'src/modals/recaptcha-verification/recaptcha-verification.component';
import { IldCountriesComponent } from 'src/modals/ild-countries/ild-countries.component';
import { WifiCallingModalComponent } from 'src/modals/wifi-calling-modal/wifi-calling-modal.component';
import { emailVerificationModalComponent } from 'src/modals/email-verification-needed-modal/email-verification-needed-modal.component';

export interface ModalSetting {
  title?: string;
  subMessage?: string;
  okText?: string;
  cancelText?: string;
  customClass?: string;
  enableHTML?: boolean;
}

export interface IModalHelper {
  showManageShippingAddressesModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, addressId?: string, customClass?: string, accountSettingsRoute?: string): MatDialogRef<any>;
  showManagePaymentContextModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, paymentId?: string, customClass?: string): MatDialogRef<any>;
  showConfirmMessageModal(title: string, message: string, okText?: string, cancelText?: string, customClass?: string): MatDialogRef<any>;
  showConfirmMessage(message: string, settings?: ModalSetting): MatDialogRef<any>;
  showConfirmPasswordModal(title: string, message: string, customClass?: string): MatDialogRef<any>;
  showInformationMessageModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string, customHTML?: string, cancelBtn?: boolean, cancelText?: string, noteText?: string): MatDialogRef<any>;
  showAddActivatedNumberModal(customClass?: string, title?: string, label?: string): MatDialogRef<any>;
  showInputModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string, isCancelable?: boolean, cancelText?: string, cancelBtnClass?: string, labelText?: string): MatDialogRef<any>;
  showRoutingModal(title: string, message: string, hasCloseLink?: boolean, yesButtonText?: string, noButtonText?: string, skipButtonText?: string, yesButtonLink?: string, noButtonLink?: string, skipButtonLink?: string, customClass?: string): MatDialogRef<any>;
  showEditCreditCardModal(paymentMethod: IFirebasePaymentMethod, title: string, note: string, noteLinkText: string, customClass?: string): MatDialogRef<any>;
  showSelectCreditCardModal(paymentList: Array<IFirebasePaymentMethod>, title: string, mdn: string, buttonText: string, customClass?: string, profileKey?: string): MatDialogRef<any>;
  showShippingAddressModal(title: string, shippingAddress?: IFirebaseAddress, customClass?: string): MatDialogRef<any>;
  showSIMModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string, network?: string, labelText?: string, showRecaptcha?: boolean): MatDialogRef<any>;
  showNotImpactedModal(title: string, message: string, customClass?: string): MatDialogRef<any>;
  showFiveGModal(title: string, customHTML: string, linkText: string, linkRoute: any, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any>;
  showAlertSecurityModal(title: string, primaryBtn?: string, secondaryBtn?: string, timer?: boolean, customClass?: string, customHTML?: string): MatDialogRef<any>;
  showSkipModal(title: string, hasCloseLink?: boolean, customClass?: string, notBeforeSkipping?: boolean): MatDialogRef<any>;
}

@Injectable({
  providedIn: 'root'
})

export class ModalHelper implements IModalHelper {

  constructor(private dialog: MatDialog) { }

  public showManageShippingAddressesModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, addressId?: string, customClass?: string, accountSettingsRoute?: string): MatDialogRef<any> {
    return this.dialog.open(ManageAddressesModalComponent, {
      data: { isManage, userPlan, user, addressId, customClass, accountSettingsRoute }
    });
  }

  public showManagePaymentContextModal(user: IUser, userPlan: IUserPlan, isManage?: boolean, paymentId?: string, customClass?: string, accountSettingsRoute?: string): MatDialogRef<any> {
    return this.dialog.open(ManagePaymentModalContextComponent, {
      data: { isManage, paymentId, userPlan, user, customClass, accountSettingsRoute }
    });
  }

  public showConfirmMessageModal(title: string, message: string, okText?: string, cancelText?: string, customClass?: string, subMessage?: string): MatDialogRef<any> {
    return this.showConfirmMessage(message, { title, subMessage, okText, cancelText, customClass, enableHTML: false });
  }

  public showConfirmMessage(message: string, settings?: ModalSetting): MatDialogRef<any> {
    return this.dialog.open(ConfirmMessageModalComponent, {
      data: { message, settings }
    });
  }

  public showConfirmPasswordModal(title: string, message: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(ConfirmPasswordModalComponent, {
      data: { title, message, customClass }
    });
  }

  public showFiveGModal(title: string, customHTML: string, linkText: string, linkRoute: any, customClass?: string, hasCloseLink?: boolean): MatDialogRef<any> {
    return this.dialog.open(FiveGModalComponent, {
      data: { title, customHTML, linkText, linkRoute, customClass, hasCloseLink }
    });
  }

  public showInformationMessageModal(title: string, message: string, btnText?: string, btnUrl?: string, hasCloseLink?: boolean, customClass?: string, customHTML?: string, cancelBtn?: boolean, cancelText?: string, noteText?: string, specificCancelReturn?: string, position?: DialogPosition): MatDialogRef<any> {
    return this.dialog.open(InformationMessageModalComponent, {
      position,
      data: { title, message, btnText, btnUrl, hasCloseLink, customClass, customHTML, cancelBtn, cancelText, noteText, specificCancelReturn }
    });
  }

  public showAddActivatedNumberModal(customClass?: string, title?: string, label?: string): MatDialogRef<any> {
    return this.dialog.open(AddActivatedNumberModalComponent, {
      data: { customClass, title, label }
    });
  }

  public showInputModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    isCancelable?: boolean, cancelText?: string, cancelBtnClass?: string, labelText?: string): MatDialogRef<any> {
    return this.dialog.open(InputModalComponent, {
      data: { title, message, okText, okBtnClass, customClass, isCancelable, cancelText, cancelBtnClass, labelText }
    });
  }

  public showRoutingModal(title: string, message: string, hasCloseLink?: boolean, yesButtonText?: string, noButtonText?: string, skipButtonText?: string, yesButtonLink?: string, noButtonLink?: string, skipButtonLink?: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(RoutingModalComponent, {
      data: { title, message, hasCloseLink, yesButtonText, noButtonText, skipButtonText, yesButtonLink, noButtonLink, skipButtonLink, customClass }
    });
  }

  public showEditCreditCardModal(paymentMethod: IFirebasePaymentMethod, title: string, note: string, noteLinkText: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(EditCcModalComponent, {
      data: { paymentMethod, title, note, noteLinkText, customClass }
    });
  }

  public showSelectCreditCardModal(paymentList: Array<IFirebasePaymentMethod>, title: string, mdn: string, buttonText: string, customClass?: string, profileKey?: string): MatDialogRef<any> {
    return this.dialog.open(SelectCCModalComponent, {
      data: { paymentList, title, mdn, buttonText, customClass, profileKey }
    });
  }

  public showShippingAddressModal(title: string, shippingAddress?: IFirebaseAddress, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(ShippingAddressModalComponent, {
      data: { title, shippingAddress, customClass }
    });
  }

  public ShowIosDataSetupModalComponent(network: string): MatDialogRef<any> {
    return this.dialog.open(IosDataSetupModalComponent, {
      data: { network }
    });
  }

  public successModal(message: string, buttonText?: string, customClass?: string, title?: string): MatDialogRef<any> {
    return this.dialog.open(successModalComponent, {
      data: {
        message, buttonText, customClass, title
      }
    });
  }

  showNotImpactedModal(title: string, message: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(PhoneNotImpactedModalComponent, {
      data: { title, message }
    });
  }

  public showSIMModal(title: string, message: string, okText?: string, okBtnClass?: string, customClass?: string,
    network?: string, labelText?: string, showRecaptcha?: boolean): MatDialogRef<any> {
    return this.dialog.open(SimReplacementModalComponent, {
      data: { title, message, okText, okBtnClass, customClass, network, labelText, showRecaptcha }
    });
  }
  showeSIMModal(iccid: string, mdn: string, title: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(eSimReplacementModalComponent, {
      data: {
        iccid, mdn, title, customClass
      }
    });
  }
  showWifiCallingModal(title: string, termsRoute: string, customClass?: string, wifiAddress?: IFirebaseAddress): MatDialogRef<any> {
    return this.dialog.open(WifiCallingModalComponent, {
      data: { title, termsRoute, customClass, wifiAddress }
    });
  }

  // showMigrationStepsModal(customClass?: string, selectedPlanId?: string): MatDialogRef<any> {
  //   return this.dialog.open(MigrationStepsComponent, {
  //     data: { customClass, selectedPlanId }
  //   });
  // }
  public showAlertSecurityModal(title: string, primaryBtn?: string, secondaryBtn?: string, timer?: boolean, customClass?: string, customHTML?: string): MatDialogRef<any> {
    return this.dialog.open(AlertSecurityModalComponent, {
      disableClose: true,
      data: {
        title, primaryBtn, secondaryBtn, timer, customClass, customHTML
      }
    });
  }
  public showSkipModal(title: string, hasCloseLink?: boolean, customClass?: string, notBeforeSkipping?: boolean): MatDialogRef<any> {
    return this.dialog.open(CompatibilitySkipModalComponent, {
      data: {
        title, hasCloseLink, customClass, notBeforeSkipping
      }
    });
  }

  public showTrackingModal(title: string, trackingDetails: IShipmentTracking, trackingNumber: string, customClass?: string): MatDialogRef<any> {
    return this.dialog.open(TrackingModalComponent, {
      data: {
        title, trackingDetails, trackingNumber, customClass
      }
    });
  }

  showIphone5GIos17InstructionsModal() {
    return this.dialog.open(InstractionsFiveGIos17ModalComponent, {
      width: '700px',
      maxWidth: '97%'
    });
  }

  public showGoogleLoginsModal() {
    return this.dialog.open(GoogleLoginComponent, {
      width: '500px',
      maxWidth: '97%',
      height: '200px'
    });
  }
  
  public showRecaptchaVerificationModal() {
    return this.dialog.open(RecaptchaVerificationComponent);
  }
  public showILDCountries() {
    return this.dialog.open(IldCountriesComponent);
  }
  showEmailVerificationNeededModal(email) : MatDialogRef<any> {
    return this.dialog.open(emailVerificationModalComponent, {
      disableClose: true,
      data: {
        email
      }
    });
  }
}