<div class="instruction-box">
    <img src="assets/icon/popup-close-icon.svg" (click)="close()" class="close-icon" width="32" height="32"
        alt="Close Icon">
    <div class="box-header">
        <h5>Set up your Data</h5>
    </div>
    <div class="box-body" [ngSwitch]="activeStep">
        <div *ngSwitchCase="1">
            <div class="description">
                <p class="smaller">1. Go to <b>Settings > Cellular</b></p>
            </div>
            <div class="image">
                <img src="assets/img/iphone17-5g-step-1.png" width="143" height="284" alt="iphone17 Step 1 image">
            </div>
        </div>
        <div *ngSwitchCase="2">
            <div class="description">
                <p class="smaller">2. Select <b>‘Cellular Data’</b></p>
            </div>
            <div class="image">
                <img src="assets/img/iphone17-5g-step-2.png" width="143" height="284" alt="iphone17 Step 2 image">
            </div>
        </div>
        <div *ngSwitchCase="3">
            <div class="description">
                <p class="smaller">3. Turn on <b>‘Use Carrier Settings’</b></p>
            </div>
            <div class="image">
                <img src="assets/img/iphone17-5g-step-3.png" width="143" height="284" alt="iphone17 Step 3 image">
            </div>
        </div>
        <div *ngSwitchCase="4">
            <div class="description">
                <p class="smaller">4. Check <b>‘Personal Hotspot’</b> APN to match the image below.</p>
            </div>
            <div class="image">
                <img src="assets/img/iphone17-5g-step-4.png" width="143" height="284" alt="iphone17 Step 4 image">
            </div>
        </div>
    </div>
    <div class="box-footer">
        <button class="button secondary" (click)="back()" *ngIf="this.activeStep > 1">Back</button>
        <button class="button primary" (click)="next()">{{activeStep < 4 ? 'Next' : 'Done' }}</button>
    </div>
</div>