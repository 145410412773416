<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32" height="32"
      alt="Close Icon">
    <div class="modal-header">
      <h4 class="modal-heading">{{context.title}}</h4>
    </div>
    <div class="modal-body">
      <form #userInputForm="ngForm">
        <div class="container modal-content">
          <div class="context-message">
            {{context.message}}
          </div>
          <div class="form-fields">
            <fieldset>
              <label class="label" for="userInputField">{{context.labelText}}</label>
              <input id="userInputField" type="text" name="userInputField" [(ngModel)]="userInputValue" id="userInput"
                (paste)="onPaste($event)" #userInputField="ngModel" required autocomplete="user-input"
                [minlength]="checkLength()" [maxlength]="checkLength()" restrictNumbers>
              <label class='validation-message' id="required-iccid-msg"
                *ngIf="!userInputValue && userInputField.touched">
                This field is required.
              </label>
              <label class='validation-message' *ngIf="!!userInputValue && userInputValue?.length < checkLength()"
                id="invalid-iccid-msg" data-cy="invalid-equipmentNumber-msg">
                Invalid ICCID.
              </label>
              <div class="primary-col"><a (click)="showIccidPart = !showIccidPart">What is an ICCID number?<img
                    src="assets/icon/circle-down-arrow-icon.svg" [class.rotate]="!!showIccidPart" width="20" height="20"
                    alt="Arrow"></a></div>
              <div class="iccid-part" *ngIf="!!showIccidPart">
                <p class="smaller">Your SIM Card’s ICCID number is a <b>20-digit number</b> on the back of your card.
                  You can find it on the top of the card or the pop up chip.</p>
                <img src="assets/img/freeup-sim-graphic.svg" width="203" height="100" alt="SIM Graphic">
              </div>
            </fieldset>
          </div>
          <div class="modal-actions">
            <button class="button {{context.okBtnClass}}" (click)="submitUserInput()"
              [class.disabled]="userInputValue?.length < validLength"
              [disabled]="userInputValue?.length < validLength">{{context.okText}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>