<div class="modal">
    <div class="container model-container {{context.customClass}}">
        <img src="assets/icon/popup-close-icon.svg" *ngIf="context.hasCloseLink" (click)="closeDialog()" class="close-icon" width="32" height="32"
        alt="Close Icon">
      <div class="modal-header">
        <h4 class="modal-heading">{{context.title}}</h4>
      </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="row">
                    <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
                </div>
                <div class="row modal-actions" *ngIf="!!context.linkText">
                    <a [href]="context.linkRoute" (click)="download()">
                        {{context.linkText}} <img class="arrow" src="../../assets/icon/5g-download-arrow.svg">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>