<div [innerHtml]="jsonLDString"></div>
<div class="home-page">
  <section class="banner-section">
    <picture class="banner-image-container">
      <source width="639" height="634" srcset='assets/img/banners/home-page-banner-mobile.webp'
        media="(max-width: 639.9px)" type="image/webp">
      <source width="639" height="634" srcset='assets/img/banners/home-page-banner-mobile.png'
        media="(max-width: 639.9px)" type="image/png">
      <source width="2000" height="530" srcset='assets/img/banners/home-page-banner.webp' media="(min-width: 640px)"
        type="image/webp">
      <source width="2000" height="530" srcset='assets/img/banners/home-page-banner.png' media="(min-width: 640px)"
        type="image/png">
      <img width="2000" height="530" src="assets/img/banners/home-page-banner.png" alt="Home Page Banner Image">
    </picture>
    <div class="banner-text-content page-section">
      <h1 class="header">UNLIMITED</h1>
      <h2 class="sub-header">Premium Wireless <b>Lowest Prices Ever!</b></h2>
      <div class="text-box">
        <p class="text">Now Just</p>
        <p class="value"><sup>$</sup>15<span class="month">/mo</span></p>
      </div>
      <button class="button primary" (click)="goToPlans()" aria-label="Shop Now!">Shop Now!</button>
    </div>
    <div class="caption-part page-section">
      <p class="caption">Limited time offer. Online & new customers only, while supplies last. Purchase 5GB 3-month
        high-speed data plan ($45 upfront payment equiv. to $15/mo). Taxes & fees extra. Promotional rate for first 3
        months only; no cash value. <a (click)="goToTerms()">See terms here</a></p>
    </div>
  </section>
  <section class="plans-section">
    <div class="page-section">
      <h2 class="title">Choose the <b>Right Plan</b> for You</h2>
      <h5>FreeUp Mobile flexible plans is the smart wireless choice!</h5>
      <h5 class="sub-title">Without compromising on speed or coverage.</h5>
    </div>
    <div class="plan-cards-part">
      <div class="desktop-plans">
        <ng-container *ngFor="let plan of featuredPlans;let i = index;">
          <div class="plan-card" [class.focus]="i===1">
            <div class="intro-offer-section">
              <img [src]="i===1 ? '/assets/icon/white-intro-promo-label.svg': '/assets/icon/intro-promo-label.svg'" alt="promo" class="promo-label" width="109" height="26">
              <p class="footer intro-offer">Introductory Offer</p>
            </div>
            <div class="internal-card-paddings">
              <div class="card-header">
                <h4 class="category">{{plan?.category}}
                </h4>
                <p class="smaller per">{{plan?.subscriptionCycles}}-Month</p>
              </div>
              <div class="plan-info">
                <div class="data info">
                  <p class="amount">{{plan?.data / 1024}}<span class="gb">GB</span></p>
                  <p class="smallest medium-font">High-speed 5G data</p>
                </div>
                <div class="border"></div>
                <div class="price info">
                  <div class="intro-price">
                    <p class="dollar">$</p>
                    <p class="price-amount">{{plan?.promoMoPrice}}</p>
                  </div>
                  <p class="smallest medium-font">per month <span class="tooltip"><img
                        [src]="i === 1 ? 'assets/icon/small-white-info-icon.svg' : 'assets/icon/small-orange-info-icon.svg'"
                        class="info-icon" alt="Info Icon" width="16" height="16">
                      <div class="tooltipInfo">
                        <p class="smallest">The Intro 3-month price is <b>${{plan?.promoMoPrice}}/mo</b>, you will be
                          charged a total of <b>${{plan?.subscriptionCycles * plan?.promoMoPrice}}</b>.</p>
                        <p class="smallest">After that the subscription will auto renew at <b>${{plan?.price}}/mo</b>, &
                          you will be charged a total of <b>${{plan?.subscriptionCycles * plan?.price}}</b>.</p>
                      </div>
                    </span></p>
                </div>
              </div>
              <div class="action">
                <button class="button" [ngClass]="{ 'secondary': i === 1, 'primary': i !== 1 }"
                  (click)="addToCart(plan)">Subscribe Now | ${{plan?.subscriptionCycles *
                  plan?.promoMoPrice}}</button>
                <button class="button tertiary" (click)="goToPlanDetails(plan?.id)"
                aria-label="View Plan Details">View Plan Details</button>
              </div>
              <div class="details">
                <p class="caption">Price will stay ${{plan?.promoMoPrice}} if upgraded to our Yearly Plan</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let plan of featuredPlans;let i = index;">
            <div class="swiper-slide plan-slide-card">
              <h4 class="category">{{plan?.category | uppercase}}</h4>
              <p class="per smaller">{{plan?.subscriptionCycles}}-Month</p>
              <div class="intro-offer-section">
                <p class="caption intro">{{ innerWidth > 639 ? 'Introductory' : 'Intro'}} Offer</p>
              </div>
              <div class="price-details">
                <div class="data-part">
                  <p class="value">{{plan?.data / 1024}}<span class="gb">GB</span></p>
                  <p class="smallest">High-speed 5G data</p>
                </div>
                <div class="border"></div>
                <div class="price-part">
                  <p class="value"><sup>$</sup>{{plan?.promoMoPrice}}</p>
                  <p class="smallest">per month <span class="tooltip"><img
                        [src]="innerWidth > 639 && innerWidth < 1440 ? 'assets/icon/small-white-info-icon.svg' : 'assets/icon/small-orange-info-icon.svg'"
                        class="info-icon" alt="Info Icon" width="16" height="16">
                      <div class="tooltipInfo">
                        <p class="smallest">The Intro 3-month price is <b>${{plan?.promoMoPrice}}/mo</b>, you will be
                          charged a total of <b>${{plan?.subscriptionCycles * plan?.promoMoPrice}}</b>.</p>
                        <p class="smallest">After that the subscription will auto renew at <b>${{plan?.price}}/mo</b>, &
                          you will be charged a total of <b>${{plan?.subscriptionCycles * plan?.price}}</b>.</p>
                      </div>
                    </span></p>
                </div>
              </div>
              <div class="action">
                <button class="button"
                  [ngClass]="{ 'secondary': i === activeSlideIndex && innerWidth > 639, 'primary': i !== activeSlideIndex || innerWidth < 640 }"
                  (click)="addToCart(plan)">Subscribe Now | ${{plan?.promoMoPrice
                  * plan?.subscriptionCycles}}</button>
              </div>
              <a class="view-plan-details" (click)="goToPlanDetails(plan?.id)">View Plan Details</a>
              <p class="desc smallest">Price will stay ${{plan?.promoMoPrice}} if upgraded to our Yearly Plan</p>
            </div>
          </ng-container>
        </div>
        <div class="hand-swiper">
          <img src="/assets/icon/hand-swipe.svg" alt="hand-swipe" width="32" height="32">
          <p class="caption swipe-note">Swipe right</p>
        </div>
        <ul class="slick-dots plan-dots slider-pagination"></ul>
      </div>
    </div>
    <div class="view-plans-button-part">
      <button class="button secondary" (click)="goToPlans()">View All Plans <img
          src="assets/icon/circle-down-arrow-icon.svg" width="20" height="20" alt="Circle Arrow Icon"></button>
    </div>
    <div class="plans-features-part page-section">
      <h3 class="features-title">Plans' Features:</h3>
      <div class="features">
        <div class="feature">
          <img src="assets/icon/map-5g-icon.svg" width="64" height="64" alt="5G US Map Icon">
          <p class="desc smaller"><b>AMERICA’S BEST 5G NETWORKS</b></p>
        </div>
        <div class="feature shipping">
          <img src="assets/icon/shipping-truck-icon.svg" width="80" height="64" alt="Shipping Truck Icon">
          <p class="desc smaller"><b>FREE SIM CARD & FREE SHIPPING</b></p>
        </div>
        <div class="feature int">
          <img src="assets/icon/unlimited-int-calling-icon.svg" width="64" height="64" alt="Int. Calling Icon">
          <p class="desc smaller"><b>UNLIMITED {{innerWidth >= 1440 || innerWidth < 640 ? 'INTERNATIONAL' : 'INT’L' }}
                CALLING</b>
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="comparison-section page-section">
    <div class="page-section">
      <h2 class="title">Save more on America’s most reliable <b>5G networks!</b></h2>
      <h5 class="sub-title">Compare and save up to <b>$180/year</b> with FreeUp Mobile!</h5>
    </div>
    <div class="table-section">
      <ul class="header-container">
        <li class="header hidden">
        </li>
        <li class="header comp1">
          <h5>Competitor #1</h5>
        </li>
        <li class="header freeup">
          <img src="assets/img/table-logo.svg" alt="freeup" />
        </li>
        <li class="header comp2">
          <h5>Competitor #2</h5>
        </li>
      </ul>
      <div class="content-container">
        <table>
          <tbody>
            <tr>
              <td>
                <p class="smaller medium-font">5G - 4G LTE Coverage</p>
              </td>
              <td> <img class="icon" src="assets/icon/trophy.svg" alt="trophy" /></td>
              <td> <img class="icon" src="assets/icon/trophy.svg" alt="trophy" /></td>
              <td><img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font price-flex">Regular Price <span class="tooltip price"><img
                      src="assets/icon/primary-info.svg" class="info-icon" alt="Info Icon" width="16" height="16">
                    <div class="tooltipInfo">
                      <p class="smallest">Unlimited Plan</p>
                    </div>
                  </span></p>
              </td>
              <td>
                <div class="price">
                  <p>$</p>
                  <h2>40</h2>
                  <p class="month">/mo</p>
                  <span class="tooltip"><img src="assets/icon/primary-info.svg" class="info-icon" alt="Info Icon"
                      width="16" height="16">
                    <div class="tooltipInfo">
                      <p class="smallest">Unlimited Plan</p>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div class="price">
                  <p>$</p>
                  <h2>30</h2>
                  <p class="month">/mo</p>
                  <span class="tooltip"><img src="assets/icon/primary-info.svg" class="info-icon" alt="Info Icon"
                      width="16" height="16">
                    <div class="tooltipInfo">
                      <p class="smallest">Unlimited Plan</p>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div class="price">
                  <p>$</p>
                  <h2>45</h2>
                  <p class="month">/mo</p>
                  <span class="tooltip"><img src="assets/icon/primary-info.svg" class="info-icon" alt="Info Icon"
                      width="16" height="16">
                    <div class="tooltipInfo">
                      <p class="smallest">Unlimited Plan</p>
                    </div>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font">Unlimited Talk & text</p>
              </td>
              <td><img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
              <td> <img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
              <td><img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font">Unlimited Data</p>
              </td>
              <td> <img class="icon" src="assets/icon/trophy.svg" alt="trophy" /></td>
              <td> <img class="icon" src="assets/icon/trophy.svg" alt="trophy" /></td>
              <td><img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font">WiFi Calling</p>
              </td>
              <td> <img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
              <td> <img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
              <td><img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font">Hotspot Data</p>
              </td>
              <td> <img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
              <td> <img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
              <td><img class="icon check" src="assets/icon/green-check.svg" alt="check" /></td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font">Int'l Calling Cost</p>
              </td>
              <td>
                <p class="medium-font">$$$</p>
              </td>
              <td>
                <div class="free"><img src="assets/icon/free.svg" alt="free" />
                  <p class="text-bold">Free</p>
                </div>
              </td>
              <td>
                <p class="medium-font">$$$</p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="smaller medium-font">Unlimited Int'l Calling</p>
              </td>
              <td>
                <p class="medium-font">Canada & Mexico <b>only</b></p>
              </td>
              <td rowspan="2" class="bottom">
                <p class="medium-font"><b>Canada & Mexico</b> and <b>60+</b> international destinations</p>
              </td>
              <td>
                <p class="medium-font">Canada & Mexico <b>only</b></p>
              </td>
            </tr>
            <tr>
              <td class="hidden"></td>
              <td class="hidden"></td>
              <td class="hidden"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <section class="why-freeup-section page-section" [class.extra-padding]="!displayedAddressModel && !!address">
    <h2 class="title">Why FreeUp Mobile?</h2>
    <h5 class="sub-title">Our promise to you</h5>
    <div class="feature-cards">
      <div class="feature-card">
        <div class="card-image">
          <img src="assets/icon/switch-save.svg" width="80" height="80" alt="Switch And Save Icon" />
        </div>
        <div class="card-content">
          <h5 class="title">Switch and Save</h5>
          <p class="desc smaller">Get more for less with a contract-free SIM! Bring your number and the phone you love
            to FreeUp Mobile and start saving today!</p>
        </div>
      </div>
      <div class="feature-card">
        <div class="card-image">
          <img src="assets/icon/reliable.svg" width="80" height="80" alt="Reliable Icon" />
        </div>
        <div class="card-content">
          <h5 class="title">Reliable</h5>
          <p class="desc smaller">Stay connected to what matters most on America’s most reliable 5G/4G LTE networks. We
            take coverage seriously!</p>
        </div>
      </div>
      <div class="feature-card">
        <div class="card-image">
          <img src="assets/icon/flexible-options.svg" width="80" height="80" alt="Flexible Options Icon" />
        </div>
        <div class="card-content">
          <h5 class="title">Flexible Plan Options</h5>
          <p class="desc smaller">Compare our unlimited data plans, and choose the monthly data amount that fits the
            needs of each your family member.</p>
        </div>
      </div>
    </div>
    <div class="map-section">
      <div class="content-part">
        <h3 class="content-title">Powered by America’s best networks</h3>
        <p class="content-desc">We’re powered by two of the country’s largest <b>5G/4G LTE</b> carriers, covering <b>99%
            of America</b>.</p>
        <div class="check-coverage-box">
          <div class="box">
            <div class="box-header">
              <img src="assets/icon/black-location.svg" width="24" height="36" alt="Location Icon">
              <h5 class="box-title">Check the coverage in your area</h5>
            </div>
            <p class="box-desc smaller">Enter your full address below and find out if FreeUp Mobile is the best fit for
              your location.</p>
            <div class="action">
              <div class="input-section">
                <form #compatibilityForm="ngForm" id="compatibilityFormDiv">
                  <input [(ngModel)]="address" matInput id="addressRef" type="search" list-formatter="description"
                    #addressRef="ngModel" name="addressRef" (focus)="setFoucs = true" (blur)="setFoucs = false"
                    class="geolocate" placeholder="Enter your full address" [matAutocomplete]="auto" required
                    (input)="changedAddress()" />
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option?.description }}</mat-option>
                  </mat-autocomplete>
                </form>
                <button class="button primary" aria-label="Let's go" id="check-address-button"
                  (click)="checkCoverage()">Let's go</button>
              </div>
              <label class="validation-message"
                *ngIf="(!address && addressRef.touched) || (!!displayValidation && !address)"
                id="required-address-msg"><b>Full address is required</b></label>
              <label class="validation-message" *ngIf="!displayedAddressModel && !!address"
                id="invalid-address-msg"><b>Please select address from the autocomplete list</b></label>
            </div>
          </div>
        </div>
      </div>
      <img src="assets/img/home-usa-map.svg" width="442" height="286" class="usa-map" alt="USA Map Image">
    </div>
  </section>
  <section class="bring-phone-section">
    <picture class="image-container">
      <source width="639" height="438" srcset='assets/img/banners/home-bring-phone-banner-mobile.webp'
        media="(max-width: 639.9px)" type="image/webp">
      <source width="639" height="438" srcset='assets/img/banners/home-bring-phone-banner-mobile.png'
        media="(max-width: 639.9px)" type="image/png">
      <source width="1024" height="793" srcset='assets/img/banners/home-bring-phone-banner-tablet.webp'
        media="(max-width: 1024.9px)" type="image/webp">
      <source width="1024" height="793" srcset='assets/img/banners/home-bring-phone-banner-tablet.png'
        media="(max-width: 1024.9px)" type="image/png">
      <source width="2000" height="515" srcset='assets/img/banners/home-bring-phone-banner.webp'
        media="(min-width: 1025px)" type="image/webp">
      <source width="2000" height="515" srcset='assets/img/banners/home-bring-phone-banner.png'
        media="(min-width: 1025px)" type="image/png">
      <img width="2000" height="515" src="assets/img/banners/home-bring-phone-banner.png"
        alt="Home Bring Phone Banner Image">
    </picture>
    <div class="banner-text-content page-section">
      <h2 class="sub-header">Bring the phone you love and start saving!</h2>
      <h5 class="description">With FreeUp Mobile, great things await!</h5>
    </div>
  </section>
</div>