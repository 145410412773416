<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <img *ngIf="context.hasCloseLink" src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon"
      width="32" height="32" alt="Close Icon">
    <div class="modal-header">
      <h4 class="modal-heading">{{context.title}}</h4>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="row">
          <div id="PROTRACTOR_MODAL_MSG" *ngIf="!context.customHTML">
            {{context.message}}
          </div>
          <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
        </div>
        <div class="row modal-actions display-block">
          <button *ngIf="!context.btnUrl" class="button primary" (click)="OK()" id="action-button">{{context.btnText ||
            'OK'}}</button>
          <a *ngIf="!!context.btnUrl" [href]="context.btnUrl" target="_blank" rel="noreferrer">
            <button class="button primary" (click)="OK()" id="modal-button">{{context.btnText || 'OK'}}</button>
          </a>
          <button *ngIf="!!context.cancelBtn" class="button secondary" (click)="cancel()"
            id="action-button">{{context.cancelText ||
            'Cancel'}}</button>
        </div>
        <div class="note" *ngIf="!!context.noteText">
          <p class="details">{{context.noteText}}</p>
        </div>
      </div>
    </div>
  </div>
</div>