import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IPageMeta } from '@ztarmobile/zwp-service';
import { ACCOUNT_ROUTE_URLS, LOGIN_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS, SUPPORT_ROUTE_URLS, ACTIVATION_ROUTE_URLS } from './app.routes.names';
import { BringPhoneComponent } from './bring-phone/bring-phone.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  // { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  {
    path: ROUTE_URLS.HOME, component: HomeComponent, data: {
      title: 'Premium Cell Phone Plans with 5G LTE Nationwide Service | FreeUp Mobile',
      description: `Affordable wireless plans with 5G LTE, international calling and messaging, and no speed restrictions. Get your free SIM card with
   free shipping or renew your plan at FreeUp Mobile.`} as IPageMeta
  },
  { path: LOGIN_ROUTE_URLS.BASE, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: ACCOUNT_ROUTE_URLS.NAME, loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: SUPPORT_ROUTE_URLS.NAME, loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: SHOP_ROUTE_URLS.NAME, loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) },
  { path: ACTIVATION_ROUTE_URLS.NAME, loadChildren: () => import('./activation/activation.module').then(m => m.ActivationModule) },
  {
    path: '', component: HomeComponent, data: {
      title: 'Premium Cell Phone Plans with 5G LTE Nationwide Service | FreeUp Mobile',
      description: `Affordable wireless plans with 5G LTE, international calling and messaging, and no speed restrictions. Get your free SIM card with
    free shipping or renew your plan at FreeUp Mobile.`} as IPageMeta
  },
  {
    path: `${LOGIN_ROUTE_URLS.LOGIN}`, redirectTo: `${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.LOGIN}`, pathMatch: 'full',
    data: {
      title: 'Login To Your FreeUp Mobile Account | FreeUp Mobile',
      description: 'Login to your FreeUp Mobile account to top up, renew, or edit your profile. Need a login? Create one here using your newly activated phone.'
    } as IPageMeta
  },
  {
    path: ROUTE_URLS.BRING_PHONE, component: BringPhoneComponent, data: {
      title: 'Keep Your Phone | FreeUp',
      description: 'Freeup supports most GSM unlocked phones'
    } as IPageMeta
  },
  { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found', description: 'Please make sure to enter a valid FreeupMobile url' } as IPageMeta }
];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
