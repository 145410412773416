<div class="modal">
  <div class="container model-container add-number-modal {{context.customClass}}">
    <div class="modal-header">
      <h2 class="modal-heading">Add an activated number</h2>
      <img src="assets/icon/popup-close-icon.svg" (click)="cancel()" class="close-icon"
      width="32" height="32" alt="Close Icon">
    </div>
    <div class="modal-body">
      <form #confirmNumberForm="ngForm">
        <div class="container modal-content">
          <div class="row">
            <div class="twelve">
              <p class="note" *ngIf="!!waitingConfirmCode">
                A text message with a verification code was sent to {{phoneNumber}} Please enter the verification code
                below.
              </p>
              <fieldset class="phone-number-section" *ngIf="!waitingConfirmCode">
                <label for="phoneNumber">Enter phone number</label>
                <input id="phoneNumber" type="tel" name="phoneNumber" [(ngModel)]="phoneNumber" required minlength="10"
                  maxlength="10" restrictNumbers>
              </fieldset>
              <div class="verification-code-section" *ngIf="!!waitingConfirmCode">
                <fieldset class="verification-code">
                  <label for="verificationCode">Verification Code</label>
                  <input id="verificationCode" type="tel" name="verificationCode" [(ngModel)]="verificationCode"
                    required minlength="6" maxlength="6" restrictNumbers>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row modal-actions">
            <button class="hidden" hidden id="verifyRecaptchaButton">Verify Recaptcha</button>
            <button class="button primary" (click)="sendCode()" type="submit" id="verifyButton"
              *ngIf="!waitingConfirmCode" [disabled]="!phoneNumber" [uiBlockButton]="processingRequest">Verify
            </button>
            <button class="button primary" (click)="verifyLoginCode()" *ngIf="waitingConfirmCode"
              [disabled]="!verificationCode || !confirmNumberForm.valid" [uiBlockButton]="processingRequest">Confirm
            </button>
            <button class="button cancel" (click)="cancel()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>