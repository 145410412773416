import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalHelper } from '@services/modal-helper.service';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrl: './google-login.component.scss'
})
export class GoogleLoginComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<any>,
  private authService: SocialAuthService, private modalHelper: ModalHelper) {
  }
  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
      if ((!!user && user?.idToken)) {
        localStorage.setItem('googleToken', user?.idToken);
        this.dialog.close();
      } else {
        localStorage.removeItem('googleToken');
      }
    });
  }
  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
    if (event.keyCode === 27) {
      event.preventDefault();
      this.dialog.close();
      this.modalHelper.showGoogleLoginsModal();
    }
  }
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!localStorage.getItem('googleToken')) {
      this.dialog.close();
      this.modalHelper.showGoogleLoginsModal();
    }
  }
}
