<div class="modal">
    <div class="container model-container ild-countries">
        <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32" height="32"
            alt="Close Icon">
        <div class="modal-top-header">
            <h4>ILD Countries</h4>
            <p>Unlimited Global <b>60+</b> Countries.</p>
        </div>

        <div class="modal-body-contents">
            <div class="search-box" [ngClass]="{'invalid': !filteredCountries?.length}">
                <div class="input-container">
                    <input #searchInput (input)="search($event.target.value)" placeholder="Search for a Country">
                </div>
                <img src="assets/icon/search.svg" alt="search img" width="40" height="40">
            </div>

            <div class="all-countries-container">
                <div *ngIf="filteredCountries?.length" class="header-section">
                    <div class="titles">
                        <p class="title smallest text-bold">Countries</p>
                        <p class="title smallest text-bold">Minutes</p>
                    </div>
                    <p class="border"></p>
                </div>

                <p *ngIf="!filteredCountries?.length" class="smaller text-bold text-center mb-0">No Data Found</p>

                <div class="contents-section">
                    <div class="contents" *ngFor="let item of filteredCountries; let i = index;"
                        [class.no-margin-bottom]="i === filteredCountries.length - 1">
                        <div class="top-section">
                            <p class="smaller country-name">{{item?.country}}</p>
                        </div>
                        <div class="offerings">
                            <div class="offers" *ngFor="let minute of item?.minutes">
                                <p class="caption offer-key">{{minute?.key}}</p>
                                <p class="caption offer-value">{{minute?.value}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>