<form [formGroup]="addressFieldsForm">
  <div class="row form-section">
    <fieldset class="address-lookup twelve" *ngIf="showAlias">
      <label for="addressAlias">Name<span class="form-text error validation-message">*</span></label>
      <input id="addressAlias" type="text" formControlName="alias" (change)="addressFieldChanged($event)"
        [readonly]="readonly" maxlength="50" (input)="validateAlias(addressFieldsForm.controls.alias.value)">
      <div class='form-text error'>
        <label class="validation-message" id="addressNameRequiredMsg"
          *ngIf="addressFieldsForm.controls.alias.touched && !addressFieldsForm.controls.alias.value && addressFieldsForm.controls.alias.hasError('required')">Name
          is a required field</label>
        <label class="validation-message" id="addressNameInvalidMsg"
          *ngIf="addressFieldsForm.controls.alias.dirty && !!addressFieldsForm.controls.alias.value && !isValidName">Name
          is invalid</label>
      </div>
    </fieldset>
    <fieldset class="address-lookup eight">
      <label for="addressLookup" *ngIf="!billingAddressAlias">Address<span
          class="form-text error validation-message">*</span></label>
      <label for="addressLookup" *ngIf="billingAddressAlias">Billing Address<span
          class="form-text error validation-message">*</span></label>
      <input formControlName="address1" matInput type="search" list-formatter="description" id="addressLookup"
        #addressLookup class="geolocate" [matAutocomplete]="auto" required (input)="changedAddress()"
        (change)="validateAddress1(addressFieldsForm.controls.address1.value)" [readonly]="readonly" />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option?.description }}
        </mat-option>
      </mat-autocomplete>
      <i class="icon-search input-search-icon"></i>
      <div class='form-text error'>
        <label class="validation-message" id="address1RequiredMsg"
          *ngIf="addressFieldsForm.controls.address1.touched && addressFieldsForm.controls.address1.hasError('required')">Address
          is a Required Field</label>
      </div>
    </fieldset>
    <fieldset class="four">
      <label for="address2">Suite/Apt. No.</label>
      <input id="address2" type="text" formControlName="address2" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="address-line2">
    </fieldset>
  </div>
  <div class="row payment-form address-lookup-details" [class.active]="hasDetails" [class.hidden]="!hasDetails">
    <fieldset class="four">
      <label for="billingCity">City <span class="form-text error validation-message">*</span></label>
      <input id="billingCity" type="text" formControlName="city" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="address-level2"
        (blur)="validateCityName(addressFieldsForm.controls.city.value)">
      <div class='form-text error'>
        <label class="validation-message" id="cityRequiredMsg"
          *ngIf="addressFieldsForm.controls.city.touched && addressFieldsForm.controls.city.hasError('required')">City
          is a Required Field</label>
      </div>
      <div class='form-text error' id="cityInvalidMsg"
        *ngIf="addressFieldsForm.controls.city.dirty && !isValidCity && addressFieldsForm.controls.city.value">
        <label class="validation-message">Invalid City </label>
      </div>
    </fieldset>
    <fieldset class="four">
      <label for="billingState">State <span class="form-text error validation-message">*</span></label>
      <input id="billingState" type="text" formControlName="state" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="address-level1" maxlength="2" minlength="2"
        (blur)="validateStateName(addressFieldsForm.controls.state.value)">
      <div class='form-text error'>
        <label class="validation-message" id="stateRequiredMsg"
          *ngIf="addressFieldsForm.controls.state.touched && addressFieldsForm.controls.state.hasError('required')">State
          is a Required Field </label>
      </div>
      <div class='form-text error' id="stateInvalidMsg"
        *ngIf="addressFieldsForm.controls.state.dirty && !isValidState && addressFieldsForm.controls.state.value">
        <label class="validation-message">Invalid State </label>
      </div>
    </fieldset>
    <fieldset class="four">
      <label for="billingPostal">Postal Code <span class="form-text error validation-message">*</span></label>
      <input id="billingPostal" type="text" formControlName="postalCode" [readonly]="readonly"
        (change)="addressFieldChanged($event)" autocomplete="postal-code" maxlength="10" minlength="5"
        (blur)="validatePostalCode(addressFieldsForm.controls.postalCode.value)">
      <div class='form-text error'>
        <label class="validation-message" id="postalCodeRequiredMsg"
          *ngIf="addressFieldsForm.controls.postalCode.touched && addressFieldsForm.controls.postalCode.hasError('required')">Postal
          Code is a Required Field</label>
      </div>
      <div class='form-text error' id="postalCodeInvalidMsg"
        *ngIf="addressFieldsForm.controls.postalCode.dirty && !isValidPostal && addressFieldsForm.controls.postalCode.value">
        <label class="validation-message">Invalid Postal Code </label>
      </div>
    </fieldset>
  </div>
  <input type="hidden" formControlName="country" autocomplete="country">
</form>