import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/app.service';
import { Subscription } from 'rxjs';

export class SIMReplacementModalContext {
  public title: string;
  public message: string;
  public okText: string;
  public okBtnClass: string;
  public customClass: string;
  public network: string;
  public labelText: string;
  public showRecaptcha?: boolean;
}
@Component({
  selector: 'app-sim-replacement-modal',
  templateUrl: './sim-replacement-modal.component.html'
})
export class SimReplacementModalComponent implements OnDestroy {
  public context: any;
  public userInputValue: string;
  public validLength;
  public showRecaptcha: boolean;
  private captchaResponse: string;
  public showIccidPart = false;
  public recaptchaResolvedSubscription: Subscription;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<SIMReplacementModalContext>, private appState: AppState
  ) {
    this.context = data;
    this.showRecaptcha = !!this.context.showRecaptcha ? this.context.showRecaptcha : false;
    this.validLength = 20;
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  ngOnDestroy(): void {
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }

  public onPaste(event: any): void {
    event.preventDefault();
  }

  closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close(false);
  }

  submitUserInput(): void {
    const action = 'freeup_device_compatibility_with_zipcode';
    this.appState.loading = true;
    this.appState.resetAndExecuteCaptchaSubject.next({ action });
    this.recaptchaResolvedSubscription = this.appState.resolvedCaptcha.subscribe(response => {
      this.captchaResponse = response;
      if(!!this.captchaResponse) {
        this.recaptchaResolvedSubscription?.unsubscribe();
        this.beforeDismiss();
        this.appState.loading = false;
        this.dialog.close({ input: this.userInputValue, captcha: this.captchaResponse, invisible: true });
      } 
    });
  }

  checkLength(): void {
    if (this.context.network && this.context.network === 'tmo') {
      this.validLength = 19;
    } else {
      this.validLength = 20;
    }
    return this.validLength;
  }
}
