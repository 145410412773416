import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { CART_TYPES, CustomizableMobilePlan, IUserAccount, IUserPlan, MobileCustomPlansService, UserAccountService, UserPlansService } from '@ztarmobile/zwp-service-backend';
import { debounceTime, take, takeWhile } from 'rxjs';
import { combineLatest } from 'rxjs/operators';
import { ROUTE_URLS, LOGIN_ROUTE_URLS, SHOP_ROUTE_URLS, ACCOUNT_ROUTE_URLS, ACTIVATION_ROUTE_URLS, SUPPORT_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS } from '../../app/app.routes.names';
import { AppState } from '../../app/app.service';
import { ModalHelper } from 'src/services/modal-helper.service';
import { PhonePipe } from '../pipes/phone.pipe';

@Component({
  selector: 'app-header-main-navbar',
  templateUrl: './header-main-navbar.component.html',
  styleUrls: ['./header-main-navbar.component.scss']
})
export class HeaderMainNavbarComponent implements OnInit, OnDestroy {
  public menuShowing = false;
  public isLoggedIn = true;
  public subAccountShowing = false;
  public hasCartItem = true;
  public totalItems = 0;
  public activeItem = 0;
  public userCart: CustomizableMobilePlan;
  public loadingPlan = true;
  public selectedPlan: IUserPlan;
  public notificationCounter = 0;
  public isExpiredAccount = false;
  public isPortIn = false;
  public ROUTE_URLS = ROUTE_URLS;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public ACTIVATION_ROUTE_URLS = ACTIVATION_ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public LOGIN_ROUTE_URLS = LOGIN_ROUTE_URLS;
  public innerWidth;
  public maintenanceExists = false;
  public showMenu: boolean;
  public userHasActivePlans = false;
  public subMenu;
  public userPlans: IUserPlan[];
  public outageBanner = false;
  
  private previousUrl: any;
  private timeout: any;
  private alive = false;

  constructor(
    private renderer: Renderer2,
    private simpleAuthService: SimpleAuthService,
    public router: Router,
    private userPlansService: UserPlansService,
    private appState: AppState,
    private angularAuthService: AngularFireAuth,
    private mobilePlansService: MobileCustomPlansService,
    private userAccountService: UserAccountService,
    private modalHelper: ModalHelper) {
  }

  ngOnInit(): void {
    this.checkIfMaintenanceExist();
    this.innerWidth = window.innerWidth;
    this.mobilePlansService.currentPlan.pipe(debounceTime(50)).subscribe((plan) => {
      this.hasCartItem = (!!plan && plan.hasPlanItem) || (!!plan && plan.simsQuantity > 0) || (!!plan && !!plan.addOns);
      this.calculateCartItems(plan);
      this.getNotificationCount();
      this.userPlansService.selectedUserPlanObservable.subscribe((selectedPlan) => {
        this.selectedPlan = selectedPlan
        if (!!this.selectedPlan) {
          this.isPortIn = !!this.selectedPlan.portInRequestNumber ? !!this.selectedPlan.portInRequestNumber : false;
        }
      });
      this.userPlansService.isSelectedPlanReady.pipe(takeWhile(() => this.alive)).subscribe((userPlanReady) => {
        this.loadingPlan = !userPlanReady;
        if (!!userPlanReady) {
          this.selectedPlan = this.userPlansService.selectedUserPlan;
        } else {
          this.userPlansService.selectFirstUserPlan(true);
        }
      });
    });
    this.simpleAuthService.userState.subscribe((authState) => {
      this.isLoggedIn = !!authState && !authState.isAnonymous;
    });
    this.userAccountService.selectedAccount.subscribe((selectedAccount) => {
      this.isExpiredAccount = !!selectedAccount && selectedAccount.pastDue;
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  public showSubMenu(menu): void {
    this.subMenu = menu === this.subMenu ? null : menu;
  }

  public planCompare(plan1: IUserPlan, plan2: IUserPlan) {
    return !!plan1 && !!plan2 ? plan1.id === plan2.id : plan1 === plan2;
  }

  public userPlanSelected(userPlan: IUserPlan): void {
    if (!!userPlan.mdn) {
      if (!this.selectedPlan || (!!userPlan && userPlan.id !== this.selectedPlan.id)) {
        if (!!this.userCart && this.userCart.cartType && this.userCart.cartType !== CART_TYPES.NEW_PLAN) {
          this.modalHelper.showConfirmMessageModal('Clear Cart', 'Changing your selected account will clear the items in your cart. Do you want to proceed?',
            'Yes', 'No', 'clean-cart-modal')
            .afterClosed().subscribe((result) => {
              if (result) {
                this.mobilePlansService.clearUserCart();
                this.appState.clearSessionStorage();
                const removedItems = [];
                if (this.userCart.cartType !== CART_TYPES.PLAN_ITEMS) {
                  removedItems.push(this.userCart.basePlan);
                } else {
                  if (this.userCart.simsQuantity > 0) {
                    removedItems.push({ id: 'SIMFRP4GLTE', quantity: this.userCart.simsQuantity, price: 5, type: 'plan-item', title: 'SIM CARD' });
                  }
                  if (!!this.userCart.addOns) {
                    removedItems.push(this.userCart.addOns);
                  }
                }
                this.userPlansService.selectUserPlan(userPlan.id);
              }
            }, (error) => {
              console.error('error', error);
            });
        } else {
          this.userPlansService.selectUserPlan(userPlan.id);
        }
      } else {
        if (!userPlan) {
          console.warn('User trying to select undefined user plan, that\'s weird!!');
        }
        else {
          this.userPlansService.selectUserPlan(userPlan.id);
        }
      }
    } else {
      this.userPlansService.selectFirstUserPlan(true);
    }
  }

  public toggleMenu(): void {
    if (!!this.showMenu) {
      this.allowScrolling()
    } else {
      this.renderer.addClass(document.body, 'modal-open');
      this.renderer.removeClass(document.body, 'modal-closed');
    }
    this.showMenu = !this.showMenu;
  }

  public allowScrolling(): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
  }

  public toggleShowing(): void {
    this.menuShowing = !this.menuShowing;
    // prevent body from scroll when menu is open
    if (this.menuShowing) {
      this.renderer.addClass(document.body, 'modal-open');
      this.renderer.removeClass(document.body, 'modal-closed');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      this.renderer.addClass(document.body, 'modal-closed');
    }
  }

  public goToAccountSummary(): void {
    this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.SUMMARY}`]);
    this.onLinkClick(event);
  }

  public goToPlans(): void {
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`]);
    this.onLinkClick(event, 'plans');
  }

  public goToPurchasedPlans(): void {
    this.mobilePlansService.setSimCard('');
    this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS}`]);
    this.onLinkClick(event);
  }

  public goToPayAndRefill(): void {
    if (!this.isPortIn) {
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PAY_AND_RENEW}`]);
    }
    this.onLinkClick(event);
  }

  public goToAddOns(): void {
    if (!this.isExpiredAccount && !this.isPortIn) {
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PLAN_ADD_ONS}`]);
    }
    this.onLinkClick(event);
  }

  public goToOrders(): void {
    this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.ORDERS}`]);
    this.onLinkClick(event);
  }

  public goToPaymentHistory(): void {
    if (!this.isPortIn) {
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PAYMENTS}`]);
    }
    this.onLinkClick(event);
  }

  public goToUsageHistory(): void {
    if (!this.isPortIn) {
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.USAGE}`]);
    }
    this.onLinkClick(event);
  }

  public goToManageDevice(): void {
    this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.MANAGE_DEVICES}`]);
    this.onLinkClick(event);
  }

  public goToProfile(): void {
    this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.SETTINGS}`]);
    this.onLinkClick(event);
  }

  public logout(event): void {
    this.menuShowing = false;
    this.setActiveItem(0);
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
    this.router.navigate([ROUTE_URLS.HOME]);
    this.appState.clearSessionStorage();
    this.angularAuthService.signOut();
    this.appState.userLoggedIn.next(undefined);
  }

  public goToQuickTopUp(): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
    this.menuShowing = false;
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.MDN_QUICK_TOP_UP}`]);
  }

  public homeClick(): void {
    this.menuShowing = false;
    this.router.navigate([ROUTE_URLS.HOME]);
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
  }

  public goToCart(): void {
    if (this.hasCartItem && !(this.router.url.indexOf('cart') > -1)) {
      this.menuShowing = false;
      this.renderer.removeClass(document.body, 'modal-open');
      this.renderer.addClass(document.body, 'modal-closed');
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
    }
  }

  public changePlan(): void {
    if (!this.isPortIn) {
      this.toggleMenu();
      if (!!this.userCart && !!this.userCart.cartType && this.userCart.cartType !== CART_TYPES.CHANGE_PLAN) {
        // eslint-disable-next-line max-len
        this.modalHelper.showConfirmMessageModal('Clear Cart', 'Purchasing a plan will remove any other item in your cart. Do you want to proceed?', 'Yes', 'No', 'clean-cart-modal')
          .afterClosed().subscribe((result) => {
            if (result) {
              this.mobilePlansService.clearUserCart();
              this.appState.clearSessionStorage();
              const removedItems = [];
              if (this.userCart.cartType !== CART_TYPES.PLAN_ITEMS) {
                removedItems.push(this.userCart.basePlan);
              } else {
                if (this.userCart.simsQuantity > 0) {
                  removedItems.push({ id: !!this.userCart.planDevice ? this.userCart.planDevice.skuNumber : 'SIMUNI4GLTE', quantity: this.userCart.simsQuantity, price: 5, type: 'plan-item', title: 'SIM CARD' });
                }
                if (!!this.userCart.addOns) {
                  removedItems.push(this.userCart.addOns);
                }
              }
              const params = {};
              params[ROUTE_URLS.PARAMS.USER_PLAN_ID] = this.selectedPlan.id;
              this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.CHANGE_PLAN}`, params]);
            }
          }, (error) => {
            console.error('error', error);
          });
      } else {
        const params = {};
        params[ROUTE_URLS.PARAMS.USER_PLAN_ID] = this.selectedPlan.id;
        this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.CHANGE_PLAN}`, params]);
      }
    }
  }

  public onLinkClick(event, page?: string): void {
    // const activeLink = event.toElement.getAttribute('routerlinkactive');
    const screenWidth = window.innerWidth;
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
    if (screenWidth < 1400) {
      this.toggleShowing();
    }
    if (!!page && (page === 'plans')) {
      this.mobilePlansService.setSimCard('');
    }
  }

  public toggleMenuAccount(): void {
    this.subAccountShowing = !this.subAccountShowing;
  }

  public setActiveItem(x: number): void {
    this.resetTimeout();
    this.activeItem = x;
  }

  public resetTimeout(): void {
    if (!!this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public calculateCartItems(plan): void {
    this.totalItems = 0;
    if (!!plan) {
      if (!!plan.cartType && plan.cartType !== CART_TYPES.PLAN_ITEMS) {
        this.totalItems = 1;
      } else {
        if (plan.addOns) {
          this.totalItems += plan.addOns.length;
        }
        if (plan.simsQuantity) {
          this.totalItems += 1;
        }
      }
    }
  }

  public getSelectorTitle(plan: IUserPlan): string {
    const mdn: string = (new PhonePipe()).transform(plan.mdn);
    const title = !!plan.portInRequestNumber ? `PortIn for ${mdn}` : (!!plan.canceled ? `${mdn} - Canceled` : mdn);
    return title;
  }

  public getNotificationCount(): void {
    if (!!this.isLoggedIn) {
      this.userAccountService.userAccounts.pipe(combineLatest(this.userPlansService.userPlans, (accounts: IUserAccount[], plans) => {
        const userAccounts = accounts.filter((account) => account.billingRenewDaysLeft <= 3);
        let pendingPlans: IUserPlan[];
        let activePlans: IUserPlan[];
        if (!!plans) {
          activePlans = plans.filter((plan) => !!plan.mdn);
          pendingPlans = plans.filter((plan) => !plan.mdn);
          this.userPlans = activePlans;
          this.userHasActivePlans = (!!activePlans && activePlans.length > 0);
        }
        if (userAccounts.length > 0) {
          this.notificationCounter = 1;
        } else {
          this.notificationCounter = pendingPlans.length;
        }
      }), takeWhile(() => this.alive)).subscribe();
    }
  }

  private checkIfMaintenanceExist(): void {
    const maxDate = new Date('February 06, 2022 02:00:00 GMT-6:00');
    const displayDate = new Date('February 04, 2022 03:00:00 GMT-6:00');
    const todayDate = new Date();
    if (todayDate < maxDate && todayDate >= displayDate) {
      this.maintenanceExists = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1399) {
      this.showMenu = false;
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event): void {
    this.menuShowing = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
  }
}
