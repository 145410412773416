export const TERMS_AND_CONDITIONS_ROUTE_URLS = {
  TERMS_AND_CONDITIONS: '',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY_NOTICE: 'privacy-notice',
  PRIVACY_POLICY: 'privacy-policy',
  MOBILE_BROADBAND: 'mobile-broadband-disclosure',
  UNLOCK_PRIVACY: 'unlocking-policy',
  OPEN_INTERNET: 'network-management',
  WIFI_CALLING: 'wifi-calling',
};

