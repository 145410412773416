<div class="modal">
    <div class="container model-container {{context.customClass || ''}}">
        <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon"
            width="32" height="32" alt="Close Icon">
        <div class="modal-header">
            <h4 class="modal-heading">{{context.title}}</h4>
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="row">
                    <div class="skip-content">
                        <p class="desc">By skipping, you’ll receive a physical SIM. Please 
                            make sure your phone is 4G VoLTE/5G compatible.</p>
                        <div class="actions">
                            <button class="button primary" data-cy="sendMePhysicalSIM"
                                (click)="skipModalOption(true)">Continue</button>
                        </div>
                        <div class="check-section">
                            <p class="not-sure">Want an eSIM?</p>
                            <button data-cy="checkDeviceForESim" class="button tertiary" (click)="skipModalOption()">Check Device for eSIM</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>