export const ROUTE_URLS = {
  PARAMS: {
    REFERENCE_PAGE: 'refPage',
    PRODUCT_ID: 'productId',
    PHONE_ID: 'phoneId',
    CATEGORY_ID: 'categoryId',
    USER_PLAN_ID: 'userPlanId',
    SELECTED_PLAN: 'selectedPlan',
    PHONE_NUMBER: 'phoneNumber',
    ACTIVATE_SIM_CARD: 'activateSimCard',
    UPDATE_PLAN: 'updatePlan',
    FAQ_QUESTION_ID: 'questionId',
    REFERRAL_ID: 'refer',
    USER_ORDER_ID: 'orderId',
    DATA_SETUP_LANDING: 'dataSetupLanding',
    INTERNATIONAL_CALL_LANDING: 'rates',
    PLAN_ADD_ON_ID: 'planAddonId',
    PHONE_OS: 'os',
    SELECTED_PLAN_ID: 'selectedPlanID',
    PLAN_ID_FROM_EMAIL: 'planIDFromEmail',
    AUTO_RENEW: 'autoRenew',
    PAYMENT_METHOD: 'paymentMethod',
    NEW_PURCHASE: 'newPurchase',
    UTM_SOURCE: 'utm_source',
    UTM_MEDIUM: 'utm_medium',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_CONTENT: 'utm_content',
    CART: 'cart',
    NETWORK: 'network'
  },
  HOME: 'home',
  BRING_PHONE: 'bring-your-phone',
  LEGAL: {
    PRIVACY_POLICY: 'support/privacy-policy',
    TERMS_AND_CONDITIONS: 'support/terms-and-conditions',
    OPEN_INTERNET: 'support/open-internet',
    PRIVACY_NOTICE: 'support/privacy-notice',
    BROADBAND: 'support/broadband',
    UNLOCKING_POLICY: 'support/unlocking-policy',
    CARD_LINKING: 'support/card-linking',
    INTERNATIONAL_TERMS_AND_CONDITIONS: 'support/international-calling-terms-and-conditions',
    INTERNATIONAL_PRIVACY_POLICY: 'support/international-calling-privacy-notice',
    EULA: 'support/eula'
  }
};
export const LOGIN_ROUTE_URLS = {
  PARAMS: {
    NEXT_PAGE: 'nextPage',
    EMAIL: 'email',
    OOB_CODE: 'oobCode',
    MODE: 'mode'
  },
  BASE: 'user',
  WELCOME: 'welcome',
  SIGN_UP: 'sign-up',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  VERIFY_EMAIL: 'verify-email'
};
export const ACCOUNT_ROUTE_URLS = {
  PARAMS: {
    NEXT_PAGE: 'nextPage',
    INTERNATIONAL_CALLING: 'internationalCalling',
    CHANGE_ADDON: 'changeAddon',
    ORDER_ID: 'id',
    TICKET_NUMBER: 'ticketNumber',
    FROM_DETAILS: 'fromDetails',
    MANAGE_CARDS: 'manageCards',
    EDIT_DEVICE: 'editDevice',
    PLAN_ID: 'planId',
    MANAGE_DEVICE: 'manageDevice'
  },
  BASE: '/account',
  NAME: 'account',
  PENDING_ACTIVATIONS: 'pending-activations',
  USAGE: 'usage',
  SUMMARY: 'summary',
  CANCEL_PLAN: 'cancel-plan',
  USER_CANCEL_PLAN_FEEDBACK: 'cancel-plan/feedback',
  PAYMENTS: 'payments',
  MANAGE_DEVICES: 'manage-devices',
  SETTINGS: 'settings',
  PAY_AND_RENEW: 'pay-and-renew',
  PLAN_ADD_ONS: 'plan-addons',
  ORDERS: 'orders',
  REPORT_ISSUE: 'orders/report-issue',
  ORDER_NOT_RECIEVED: 'orders/report-issue/order-not-recieved',
  RETURN_DEVICE: 'orders/report-issue/return-device',
  EDIT_ADDRESS: 'orders/report-issue/edit-address',
  WRONG_ITEMS: 'orders/report-issue/wrong-items',
  SOMETHING_ELSE: 'orders/report-issue/something-else',
  PACKAGE_TRACKING: 'orders/package-tracking',
  CONFIRMATION: 'orders/confirmation',
  PAYMENTS_RECEIPT_DETAILS: 'payments/receipt-details',
  ORDERS_RECEIPT_DETAILS: 'orders/receipt-details',
  ORDER_DETAILS: 'orders/details',
  ESIM_SETUP: 'esim/setup'
};
export const SHOP_ROUTE_URLS = {
  BASE: '/shop',
  NAME: 'shop',
  PLAN_DETAILS: 'plan-details',
  CUSTOMIZE_PLAN: '',
  CHECKOUT: 'checkout',
  CHECKOUT_SHORT: 'checkout',
  PLACE_ORDER: 'checkout/order',
  PLANS_AND_FEATURES: 'plans',
  PLANS_AND_FEATURES_SHOP: 'plans-and-features',
  PLANS_SHOP: 'plans/:id/details',
  CHANGE_PLANS_SHOP: 'change/:id/details',
  CHECKOUT_RESULTS: 'checkout-results',
  CHECK_PHONE: 'check-phone',
  MDN_QUICK_TOP_UP: 'quick-top-up',
  VOUCHER_QUICK_TOP_UP: 'quick-top-up/voucher',
  SUCCESS_QUICK_TOP_UP: 'quick-top-up/success',
  PHONES_SHOP: 'https://www.myfreeupmobilephones.com/FreeUP-Smart-Phones.html?utm_source=FreeUPMobile&utm_medium=Referral',
  CART: 'cart',
  PHONES: {
    MAIN: '',
    TYPE: 'phones',
    MODEL: 'phones/model',
    DETAILS: 'phones/details'
  },
  PARAMS: {
    CHANGE_NEXT_CYCLE: 'nextCycle',
    TOP_UP_PLAN: 'topUpPlan',
    ADD_ON_PLAN: 'addOnPlan',
    ORDER_SIM: 'orderSim',
    SUCCESSFUL_PURCHASE: 'successfulPurchase',
    MDN: 'mdn',
    IS_CHANGE_PLAN: 'changePlan',
    SELECTED_TAB: 'selectedTab',
    IS_EXPAND: 'expandPlans',
    CYCLES: 'cycles',
    LAYOUT: 'layout'
  },
};

export const PLANS_SHOP_ROUTE_URLS = {
  NEW_PLAN: 'new',
  CHANGE_PLAN: 'change',
  CHANGE_PLAN_ID: 'change/:id',
  DETAILS: 'details',
  DETAILS_ID: 'details/:id',
  BASE: '/plans',
  CHANGE_SUMMARY: 'change-summary',
};

export const SUPPORT_ROUTE_URLS = {
  BASE: '/support',
  NAME: 'support',
  FAQS: 'faqs',
  FAQS_CATEGORY: 'faqs/:category',
  FAQS_QUESTION_ID: 'faqs/:category/:id',
  SUPPORT_CATEGORY: 'faqs/support',
  COVERAGE: 'coverage',
  LANDING_COVERAGE: 'best-wireless-coverage',
  CONTACT_US: 'contact',
  CONTACT_US_RESULTS: 'contact-us-results',
  HEARING_AID_COMPATIBILITY: 'hearing-aid-compatibility',
  DATA_SETUP: 'data-setup',
  ATT_IPHONE: 'data-setup/att/ios',
  IPHONE4G: 'data-setup/att/ios/4G',
  IPHONE5G: 'data-setup/att/ios/5G',
  ATT_ANDROID: 'data-setup/att/android',
  ANDROID4G: 'data-setup/att/android/4G',
  ANDROID5G: 'data-setup/att/android/5G',
  TMO_IPHONE: 'data-setup/tmo/ios',
  TMO_Android: 'data-setup/tmo/android',
  IPHONE_DATA_SETUP: 'iphone-data',
  CDMA_PROGRAMMING: 'cdma-programming',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  INT_CALLING_TERMS_AND_CONDITIONS: 'int-calling-terms-and-conditions',
  SITEMAP: 'sitemap',
  STORE_LOCATOR: 'store-locator',
  INTERNATIONAL_CALLING: 'international-calling',
  FREEUP_TALK: 'freeup-talk',
  INTERNATIONAL_SMS: 'international-sms',
  DEALERS: 'dealers',
  SWITCH_AND_SAVE: 'switch-and-save-on-cellphone-wireless-plans',
  HD_VOICE: 'hd-voice-audio-and-reduced-background-noise-calling',
  WIFI_CALLING: 'wifi-calling',
  SHUTDOWN_3G: '3G_shutdown',
  PARAMS: {
    TARGETID: 'targetId',
    NETWORKTYPE: 'networkType',
    MDN: 'mdn'
  },
};

export const CHECKOUT_ROUTE_URLS = {
  NEW_CUSTOMER: 'customerinfo/new',
  EXISTING_CUSTOMER: 'customerinfo/existing',
  SHIPPING_SECTION: 'shipping',
  PAYMENT_SECTION: 'billing',
  PLACE_ORDER: 'order',
  NAME: 'checkout',
  BASE: '/checkout'
};

export const ACTIVATION_ROUTE_URLS = {
  PARAMS: {
    PORTIN_NUMBER: 'portIn',
    ACTIVATION_CODE: 'code',
    COMPATABILE: 'compatibile',
    NETWORK: 'network',
    ZIP_CODE: 'zipCode',
    ICCID: 'iccid',
    ACTIVATION: 'activate',
    ACTIVATE_PLAN: 'activatePlan',
    ESIM: 'eSim',
    ESIMOnly: 'eSimOnly'
  },
  BASE: '/activation',
  NAME: 'activation',
  CHECK_PHONE: 'check-phone',
  ACTIVATE_PLAN: 'activate-plan',
  ACTIVATE_SIM: 'activate-sim',
  CHOOSE_PLANS_PATH: 'choose-plan',
  CHOOSE_ACTIVATION_PATH: 'choose-activation',
  ACTIVATION_SUMMARY: 'summary',
  CHECK_PHONE_RESULT: 'check-phone/result',
  VERIFY_PHONE: '',
  CHOOSE_SIM_PATH: 'choose-sim',
  REPLACE_SIM: 'replace-sim',
  SIM_CHECK: 'sim-check',
  No_SIM: 'no-sim',
  CHOOSE_TRIAL: 'upgade-trial',
  MIGRATE_SIM: 'migrate-sim',
  ACTIVATE_NEW: 'activate-new-number',
  PORT_NUMBER: 'keep-your-current-number',
  PORT_SUBMITTED: 'port-request-submitted',
  ESIM_PATH: 'eSIM-activation',
  ESIM_CONFIRMATION: 'eSIM-confirmation',
  ESIM_REPLACEMENT: 'eSIM-Replacement',
  ESIM_CANCELLATION: 'cancel-eSIM',
  ESIM_START_SETUP: 'start-eSIM-setup',
  ESIM_SETUP: 'eSIM-setup',
  ESIM_SUCCESS_SETUP: 'successful-eSIM-setup'
};

export const MIGRATION_ROUTE_URLS = {
  BASE: '/migration',
  NAME: 'migration',
  SIM_RECEIVED: 'sim-received',
  CHECK_COMPATIBILITY: 'check-device',
  CONFIRM_DEVICE: 'confirm-device',
  COMPATIBLE_DEVIE: 'compatible-device',
  INCOMPATIBLE_DEVICE: 'incompatible-device',
  SUCCESS_ORDER: 'successful-order',
  PARAMS: {
    CONFIRMED: 'confirmed',
    ORDERED: 'ordered',
    DEVICE: 'id'
  }
};

