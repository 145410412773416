/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ChangeDetectorRef, HostListener, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountPaymentService, ActionsAnalyticsService, CART_TYPES, CustomizableMobilePlan, FirebaseAdsService, IAutoCompletePrediction, IBasePlan, IFirebaseAddress, MobileCustomPlansService, PURCHASE_INTENT, PlacesAutocompleteService } from '@ztarmobile/zwp-service-backend';
import { takeWhile } from 'rxjs/operators';
import { CUSTOMER_CARE_NUMBER, WEBSITE_URL } from 'src/environments/environment';
import { MetaService } from 'src/services/meta-service.service';
import { ACTIVATION_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS, SHOP_ROUTE_URLS, SUPPORT_ROUTE_URLS } from '../app.routes.names';
import { AppState } from '../app.service';
import { Observable, Subscription } from 'rxjs';
import { EquipmentService } from '@ztarmobile/zwp-service-backend-v2';
import { NgForm } from '@angular/forms';
import { ToastrHelperService } from 'src/services/toast-helper.service';
import Swiper, { EffectCards, Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ModalHelper } from 'src/services/modal-helper.service';
import { ATT, CAPTCHA_BOT_ERROR_CODE, PARAM_ERROR_MESSAGES, TMO } from '../app.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('compatibilityForm') compatibilityForm: NgForm;

  public jsonLDString: any;
  public innerWidth: any;
  public featuredPlans: IBasePlan[];
  public defaultPlan: IBasePlan;
  public index = 0;
  public processingRequest = false;
  public recaptchaResponse: any;
  public invalidAddress = false;
  public displayedAddressModel: any;
  public address: any;
  public setFoucs = false;
  public displayValidation = false;
  public networkType: string;
  public filteredOptions: Observable<Array<IAutoCompletePrediction>>;
  public filteredOptionsSubscription: Subscription;
  public swiper: any;
  public config: SwiperOptions = {
    direction: 'horizontal',
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    centeredSlides: true,
    roundLengths: false,
    loopFillGroupWithBlank: false,
    slideToClickedSlide: true,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    initialSlide: 1,
    slidesPerView: 'auto',
    centeredSlidesBounds: true,
    observer: true,
    effect: 'slide',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.slider-pagination',
      renderBullet: (index, className) => `<li style="z-index: 10" class="slick-active ${className}"><button ></button></li>`,
      clickable: true
    },
    spaceBetween: 0
  };
  public userCart: CustomizableMobilePlan;
  public userHasDevice = false;
  public activeSlideIndex = 1;
  public recaptchaResolvedSubscription: Subscription;

  private streetSearchText: string;
  private tracked: boolean;
  private alive = true;

  constructor(
    private appState: AppState,
    private firebaseAdsService: FirebaseAdsService,
    public router: Router,
    public toastHelper: ToastrHelperService,
    private metaService: MetaService,
    private placesAutoCompleteService: PlacesAutocompleteService,
    private equipmentService: EquipmentService,
    private cdr: ChangeDetectorRef,
    private simpleAuthService: SimpleAuthService,
    private angularFireService: AngularFireAuth,
    private mobilePlansService: MobileCustomPlansService,
    private modalHelper: ModalHelper,
    private analyticsService: ActionsAnalyticsService) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.metaService.createCanonicalUrl(WEBSITE_URL);
    this.mobilePlansService.currentPlan.pipe(takeWhile(() => this.alive)).subscribe((cart) => {
      this.userCart = cart;
      this.userHasDevice = !!this.userCart.planDevice && !!this.userCart.planDevice.skuIdentifier && !!this.userCart.planDevice.skuNumber;
    });
    this.prepareSchemaData();
    this.firebaseAdsService.featuredPlans.pipe(takeWhile(() => this.alive)).subscribe((plans) => {
      this.featuredPlans = plans;
      this.featuredPlans.sort((a, b) => a.data - b.data); // sort plans based on data
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cdr.detectChanges();
      this.createSwiper();
    }, 200);
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.recaptchaResolvedSubscription) {
      this.recaptchaResolvedSubscription.unsubscribe();
    }
  }

  public goToTerms() {
    this.router.navigateByUrl(`${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS}`);
  }

  public goToPlans(): void {
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`]);
  }

  public goToPlanDetails(id): void {
    const params = {};
    params['id'] = id;
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}${PLANS_SHOP_ROUTE_URLS.BASE}/${PLANS_SHOP_ROUTE_URLS.DETAILS}`, params['id']]);
  }

  public addressDetails(eventFire: IAutoCompletePrediction): void {
    if (!!eventFire && !!this.address && this.address?.main_text) {
      const event = this.address;
      if (!!event.place_id) {
        this.appState.loading = true;
        this.invalidAddress = false;
        this.displayValidation = false;
        //this is a default value until address have the value from api
        this.address = event?.main_text;
        this.placesAutoCompleteService
          .findDetailedAddressFields(event.place_id)
          .subscribe(
            (place) => {
              this.streetSearchText =
                !!place.address1 && place.address1.length > 0
                  ? place.address1
                  : null;
              this.displayedAddressModel = this.getAddressValues(
                place,
                event.main_text
              );
              this.address = `${this.displayedAddressModel?.address1}, ${this.displayedAddressModel?.city
                }, ${this.displayedAddressModel?.state} ${this.displayedAddressModel?.postalCode
                  ? this.displayedAddressModel?.postalCode
                  : ''
                }`;
              this.appState.loading = false;
            },
            (error) => {
              this.appState.loading = false;
              console.warn(
                `Google can't find details for place: ${event.place_id}`,
                error
              );
            }
          );
      } else {
        this.invalidAddress = true;
        this.displayValidation = false;
        console.warn(`Google can't find place: ${event.main_text}`);
      }
    } else {
      this.invalidAddress = true;
    }
  }

  public changedAddress(): void {
    this.findPlace(this.address);
    this.displayedAddressModel = null;
  }

  public addToCart(plan): void {
    this.checkUser(plan);
  }

  public checkCoverage(): void {
    this.compatibilityForm.form.markAllAsTouched();
    const action = 'freeup_check_coverage_by_imei';
    if (!!this.address && !!this.displayedAddressModel) {
      this.appState.loading = true;
      this.appState.resetAndExecuteCaptchaSubject.next({action});
      this.recaptchaResolvedSubscription= this.appState.resolvedCaptcha.subscribe(response => {
        this.recaptchaResponse = response;
        if(!!this.recaptchaResponse) {
          this.recaptchaResolvedSubscription?.unsubscribe();
          this.displayValidation = false;
          this.callCheckCoverageApi(this.recaptchaResponse, true, action);
        }
      });
    } else if (!this.displayedAddressModel && !this.address) {
      this.displayValidation = true;
    }
  }

  public callCheckCoverageApi(captchaRes, invisibleCaptchaRes, action?): void {
    this.equipmentService.checkNetworkCoverageByAddress(captchaRes, this.displayedAddressModel?.postalCode,
      this.displayedAddressModel?.address1, this.displayedAddressModel?.city,
      this.displayedAddressModel?.state, this.displayedAddressModel?.address2, !!action?action:null, invisibleCaptchaRes).then(res => {
        if (!!res) {
          this.appState.loading = false;
          if (!!res.covered) {
            const tmoCoverage = res.coveredNetworks.find(i => i.network.toLowerCase() === TMO.toLowerCase());
            const attCoverage = res.coveredNetworks.find(i => i.network.toLowerCase() === ATT.toLowerCase());
            // Select ATT coverage first then TMO
            const defaultNetwork = !!attCoverage ? attCoverage : tmoCoverage;
            this.networkType = defaultNetwork.network || ATT; // just to detault to ATT

            const params = {};
            params[SUPPORT_ROUTE_URLS.PARAMS.NETWORKTYPE] = this.networkType;
            params[ACTIVATION_ROUTE_URLS.PARAMS.ZIP_CODE] = this.displayedAddressModel?.postalCode;
            this.router.navigate([`${SUPPORT_ROUTE_URLS.BASE}/${SUPPORT_ROUTE_URLS.COVERAGE}`, params]);
          }
        }
      }, (error) => {
        this.handleCompatibilityError(error);
      }).catch((error)=>{
        this.handleCompatibilityError(error);
      })
  }
  public handleCompatibilityError(error) {
    this.appState.loading = false;
    this.recaptchaResolvedSubscription?.unsubscribe();
    const errorDetails = error?.error?.errors[0];
    let errorMessage = errorDetails?.message;
    
    if(errorDetails?.code !== CAPTCHA_BOT_ERROR_CODE) {    
      this.displayValidation = false;
      if (errorDetails?.code === 102) {
        const paramName = errorDetails.params[0]?.param_name;
        const paramMessage = errorDetails.params[0]?.message;
  
        if (paramName.includes('equipment') || paramName.includes('imei')) {
          errorMessage = PARAM_ERROR_MESSAGES.IMEI;
        } else if (paramMessage === 'Invalid zip code') {
          errorMessage = PARAM_ERROR_MESSAGES.ADDRESS;
        }
      }
  
      // If it's not a compatibility error, use the original error message
      errorMessage = errorMessage || errorDetails?.message || PARAM_ERROR_MESSAGES.GENERIC;
  
      this.toastHelper.showAlert(errorMessage);
    } else if(errorDetails?.code === CAPTCHA_BOT_ERROR_CODE) {
      this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result) => {
        if (!!result) {
          this.appState.loading = true;
          this.callCheckCoverageApi(result, false);
        }
      });
     }
  }
  private findPlace(keyword: ''): Observable<Array<IAutoCompletePrediction>> {
    this.filteredOptions = this.placesAutoCompleteService.findAddress(keyword);
    this.filteredOptionsSubscription = this.filteredOptions.subscribe();
    return this.filteredOptions;
  }

  private checkUser(plan): void {
    this.simpleAuthService.userState.pipe(takeWhile(() => this.alive)).subscribe((authState) => {
      const isLoggedIn = !!authState && !authState.isAnonymous;
      if (!isLoggedIn) {
        this.angularFireService.signInAnonymously().then((user) => {
          setTimeout(() => {
            this.checkCart(plan);
          }, 1000);
        });
      } else {
        this.checkCart(plan);
      }
    });
  }

  private checkCart(plan): void {
    // eslint-disable-next-line
    if (!!this.userCart && !!this.userCart.cartType && this.userCart.cartType != CART_TYPES.NEW_PLAN) {
      this.modalHelper.showConfirmMessageModal('Clear Cart', 'Adding new plan will remove other items in your cart. Do you want to proceed?', 'Yes', 'No', 'clean-cart-modal')
        .afterClosed().subscribe((result) => {
          if (!!result) {
            setTimeout(() => {
              this.addPlan(plan);
              if ((!this.userCart.planDevice) || (!!this.userCart.planDevice && !this.userCart.planDevice.id) ||
                (!!this.userCart.planExpectedDevice && !this.userCart.planExpectedDevice.id)) {
                this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECK_PHONE}`]);
              } else {
                this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
              }
            }, 500);
          }
        }, (error) => {
          console.error('error', error);
          return false;
        });
    } else {
      this.addPlan(plan);
      this.mobilePlansService.setActivePlanId('');
      if ((!this.userCart.planDevice && !this.userCart.planExpectedDevice) || (!!this.userCart.planDevice && !this.userCart.planDevice.id) ||
        (!!this.userCart.planExpectedDevice && !this.userCart.planExpectedDevice.id)) {
        this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECK_PHONE}`]);
      } else {
        this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
      }
    }
  }

  private addPlan(plan): void {
    sessionStorage.setItem('planID', JSON.stringify(plan.id));
    if (!!this.userHasDevice) {
      this.mobilePlansService.setBasePlan(plan);
      this.mobilePlansService.setCartType(CART_TYPES.NEW_PLAN);
      this.mobilePlansService.setAutoRenewPlan(true);
    }
    const price = plan.subscriptionCycles * plan.price;
    if (!this.tracked) {
      this.tracked = true;
      this.analyticsService.trackAddToCartGA4(PURCHASE_INTENT.NEW, [plan], price);
    }
  }

  private createSwiper(): void {
    this.swiper = new Swiper('.swiper-container', {
      hashNavigation: true,
      modules: [Navigation, Pagination, Keyboard],
      ...this.config,
    });
    this.cdr.detectChanges();
    setTimeout(() => {
      this.swiper?.on('slideChange', () => {
        this.activeSlideIndex = this.swiper?.activeIndex;
      });
    }, 200);
    this.cdr.detectChanges();
  }

  private getAddressValues(
    placeInfo: any,
    searchTerms?: string
  ): IFirebaseAddress {
    let address: IFirebaseAddress = {
      name: !!this.displayedAddressModel?.name
        ? this.displayedAddressModel?.name
        : this.displayedAddressModel?.alias,
      address1: placeInfo.address1,
      address2: placeInfo.address2,
      state: placeInfo.state ? placeInfo.state.toUpperCase() : placeInfo.state,
      city: placeInfo.city,
      country: placeInfo.country || 'United States',
      postalCode: placeInfo.postalCode,
    } as IFirebaseAddress;

    if (!!this.displayedAddressModel?.id) {
      address.id = this.displayedAddressModel?.id;
      address.isDefault = this.displayedAddressModel?.isDefault;
    }
    if (!!searchTerms && typeof searchTerms === 'string') {
      if (!!this.streetSearchText) {
        if (!searchTerms.match(this.streetSearchText)) {
          this.streetSearchText = null;
        }
      }
      address.address1 = !!this.streetSearchText
        ? address.address1
        : searchTerms.trim();
    }
    if (!!address && address.address1)
      address.address1 = AccountPaymentService.shortenAddress(address.address1, 30);

    // Clean Out empty values,
    address = this.appState.removeEmptyValues(address);
    return Object.assign({}, address);
  }

  private prepareSchemaData(): void {
    const structuredData = {
      '@type': 'Organization',
      '@id': `${WEBSITE_URL}/#organization`,
      name: 'FreeUp Mobile',
      url: WEBSITE_URL,
      logo: `${WEBSITE_URL}/assets/img/Freeup-Logo.svg`,
      contactPoint: [{
        '@type': 'ContactPoint',
        telephone: CUSTOMER_CARE_NUMBER,
        contactType: 'Customer Care',
        areaServed: 'US'
      }],
      sameAs: ['https://facebook.com/freeupmobile', 'https://twitter.com/freeupmobile', 'https://instagram.com/freeupmobile',
        'https://www.youtube.com/channel/UCD01UiqOExQwdoBWrislw0A?view_as=subscriber']
    };
    this.jsonLDString = this.appState.setJsonLdData(structuredData);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.createSwiper();
  }
}
