<footer class="footer page-section">
	<div class="main-links">
		<div class="links-group">
			<div class="title-part" (click)="toggleMenu('Shop')">
				<h5 class="title">Shop</h5>
				<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
					[class.rotate]="openedMenu == 'Shop'">
			</div>
			<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Shop' }">
				<a
					[routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]">Cell
					phone plans</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INTERNATIONAL_CALLING]">International
					calling</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INTERNATIONAL_SMS]">International
					sms</a>
			</div>
		</div>
		<div class="border"></div>
		<div class="links-group">
			<div class="title-part" (click)="toggleMenu('Account')">
				<h5 class="title">Account</h5>
				<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
					[class.rotate]="openedMenu == 'Account'">
			</div>
			<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Account' }">
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]">Account summary</a>
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]">Pay & refill your
					account</a>
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]">Your orders</a>
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAYMENTS]">Payment history</a>
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.USAGE]">Usage</a>
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]">Manage devices</a>
				<a [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SETTINGS]">Profile settings</a>
			</div>
		</div>
		<div class="border"></div>
		<div class="links-group">
			<div class="title-part" (click)="toggleMenu('Support')">
				<h5 class="title">Support</h5>
				<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
					[class.rotate]="openedMenu == 'Support'">
			</div>
			<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Support' }">
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.LANDING_COVERAGE]">Coverage</a>
				<a [routerLink]="[ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.CHECK_PHONE]">Check
					compatibility</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.DATA_SETUP]">Setup your phone
					data</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.FAQS]">FAQs</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.CONTACT_US]">Contact us</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.SITEMAP]">Sitemap</a>
				<a [routerLink]="[ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.SIM_CHECK]">Activate</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.SWITCH_AND_SAVE]">Switch & save</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.HD_VOICE]">HD Voice</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.WIFI_CALLING]">Wifi Calling</a>
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.HEARING_AID_COMPATIBILITY]">Hearing
					aid compatibility</a>
			</div>
		</div>
		<div class="border"></div>
		<div class="links-group">
			<div class="title-part" (click)="toggleMenu('Terms')">
				<h5 class="title">Terms & Conditions</h5>
				<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
					[class.rotate]="openedMenu == 'Terms'">
			</div>
			<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Terms' }">
				<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS]">Terms and
					conditions</a>
				<a
					[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS + '/' + TERMS_AND_CONDITIONS_ROUTE_URLS.TERMS_OF_USE]">Terms
					of use</a>
			</div>
			<ng-container *ngIf="innerWidth > 1299">
				<div class="title-part top" (click)="toggleMenu('IntCallingTerms')">
					<h5 class="title">Intl. Calling Terms & Conditions</h5>
					<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
						[class.rotate]="openedMenu == 'IntCallingTerms'">
				</div>
				<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'IntCallingTerms' }">
					<a
						[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INT_CALLING_TERMS_AND_CONDITIONS + '/' + INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS.INT_CALLING_TERMS_OF_USE]">International
						Calling Terms of Use</a>
					<a
						[routerLink]="[ SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INT_CALLING_TERMS_AND_CONDITIONS + '/' + INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS.INT_CALLING_PRIVACY_POLICY]">International
						calling Privacy Policy</a>
					<a
						[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INT_CALLING_TERMS_AND_CONDITIONS + '/' + INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS.INT_CALLING_EULA]">EULA</a>
				</div>
				<ng-container *ngIf="innerWidth < 1721">
					<div class="title-part top" (click)="toggleMenu('Dealers')">
						<h5 class="title">Dealers</h5>
						<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
							[class.rotate]="openedMenu == 'Dealers'">
					</div>
					<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Dealers' }">
						<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.DEALERS]">Dealers form</a>
					</div>
				</ng-container>
			</ng-container>
		</div>
		<ng-container *ngIf="innerWidth < 1300">
			<div class="border"></div>
			<div class="links-group">
				<div class="title-part" (click)="toggleMenu('IntCallingTerms')">
					<h5 class="title">Intl. Calling Terms & Conditions</h5>
					<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
						[class.rotate]="openedMenu == 'IntCallingTerms'">
				</div>
				<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'IntCallingTerms' }">
					<a
						[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INT_CALLING_TERMS_AND_CONDITIONS + '/' + INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS.INT_CALLING_TERMS_OF_USE]">International
						Calling Terms of Use</a>
					<a
						[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INT_CALLING_TERMS_AND_CONDITIONS + '/' + INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS.INT_CALLING_PRIVACY_POLICY]">International
						calling Privacy Policy</a>
					<a
						[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.INT_CALLING_TERMS_AND_CONDITIONS + '/' + INT_CALLING_TERMS_AND_CONDITIONS_ROUTE_URLS.INT_CALLING_EULA]">EULA</a>
				</div>
			</div>
		</ng-container>
		<div class="border"></div>
		<div class="links-group">
			<div class="title-part" (click)="toggleMenu('Privacy')">
				<h5 class="title">Privacy Policy</h5>
				<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
					[class.rotate]="openedMenu == 'Privacy'">
			</div>
			<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Privacy' }">
				<a
					[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS + '/' + TERMS_AND_CONDITIONS_ROUTE_URLS.PRIVACY_NOTICE]">Privacy
					notice</a>
				<a
					[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS + '/' + TERMS_AND_CONDITIONS_ROUTE_URLS.MOBILE_BROADBAND]">Mobile
					broadband consumer disclosure</a>
				<a
					[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS + '/' + TERMS_AND_CONDITIONS_ROUTE_URLS.UNLOCK_PRIVACY]">Unlocking
					policy</a>
				<a
					[routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.TERMS_AND_CONDITIONS + '/' + TERMS_AND_CONDITIONS_ROUTE_URLS.OPEN_INTERNET]">Open
					Internet & broadband information</a>
			</div>
			<ng-container *ngIf="innerWidth > 1720">
				<div class="title-part top" (click)="toggleMenu('Dealers')">
					<h5 class="title">Dealers</h5>
					<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
						[class.rotate]="openedMenu == 'Dealers'">
				</div>
				<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Dealers' }">
					<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.DEALERS]">Dealers form</a>
				</div>
			</ng-container>
		</div>
		<ng-container *ngIf="innerWidth < 1300">
			<div class="border"></div>
			<div class="links-group">
				<div class="title-part" (click)="toggleMenu('Dealers')">
					<h5 class="title">Dealers</h5>
					<img src="assets/icon/footer-arrow.svg" class="title-arrow" width="24" height="24"
						[class.rotate]="openedMenu == 'Dealers'">
				</div>
				<div class="links-part" [ngClass]="{ 'opened': openedMenu == 'Dealers' }">
					<a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.DEALERS]">Dealers form</a>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="description-part">
		<p class="footer">©2024 FreeUp Mobile. All rights reserved.</p>
		<div class="social-media-links">
			<a href="https://facebook.com/freeupmobile" target="_blank" rel="noopener">
				<img src="assets/icon/third-facebook-icon.svg" width="36" height="36" alt="Facebook Icon">
			</a>
			<a href="https://twitter.com/freeupmobile" target="_blank" rel="noopener">
				<img src="assets/icon/third-x-icon.svg" width="36" height="36" alt="X Icon">
			</a>
			<a href="https://instagram.com/freeupmobile" target="_blank" rel="noopener">
				<img src="assets/icon/third-insta-icon.svg" width="36" height="36" alt="Instagram Icon">
			</a>
		</div>
	</div>
	<div class="build">
		Build version: {{BUILD_VERSION}} - {{BUILD_DATE}} <span style="display: none"> -
			{{FIREBASE_CONF.projectId}}</span>
	</div>
	<app-invisible-recaptcha (resolved)="resolvedCaptcha($event)" [siteKey]="SITE_ID" #invisibleReCaptcha>
    </app-invisible-recaptcha>
</footer>