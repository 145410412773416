<div class="modal">
  <div class="container model-container impacted-modal">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
      <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32" height="32"
        alt="Close Icon">
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="row">
          <p class="message">
            {{context.message}}
          </p>
        </div>
        <div class="row modal-actions">
          <button class="button primary" (click)="takeAction('summary')">Go to my Account Summary</button>
          <button class="button secondary" (click)="takeAction()">Check another phone number</button>
          <p class="not-working-link" (click)="takeAction('not-working')">My Phone is Not Working!</p>
        </div>
      </div>
    </div>
  </div>
</div>