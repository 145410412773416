
/* eslint-disable quote-props */
import { Injectable } from '@angular/core';
import * as contentful from 'contentful';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PHONE_SPACE } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
  export class PhonesService {
    private client = contentful.createClient({
      space: PHONE_SPACE.spaceId,
      accessToken: PHONE_SPACE.token,
      environment: PHONE_SPACE.environment
    });

    constructor() { }

    public getField(contentId): Observable<any> {
      const promise = this.client.getEntry(contentId);
      return from(promise)
        .pipe(
          map(entry => entry.fields)
        );
    }
    public getContent(contentId): Observable<any> {
      const p = this.client.getEntries({ content_type: contentId, limit: 300 });
      return from(p)
        .pipe(
          map(entry => entry.items)
        );
    }
    public getphoneTypes(contentId, categoryId): Observable<any> {
      const p = this.client.getEntries({
        'fields.categoryId': categoryId,
        'content_type': contentId
      });
      return from(p)
        .pipe(
          map(entry => entry.items)
      );
    }
    public getphoneName(contentId, phoneId): Observable<any> {
      const p = this.client.getEntries({
        'fields.phoneId': phoneId,
        'content_type': contentId
      });
      return from(p)
        .pipe(
          map(entry => entry.items[0].fields)
      );
    }
    public getCategoryId(contentId, phoneId): Observable<any> {
      const p = this.client.getEntries({
        'fields.phoneId': phoneId,
        'content_type': contentId
      });
      return from(p)
        .pipe(
          map(entry => entry.items[0].fields)
        );
    }
    public getPhoneSpecData(contentId, phoneId): Observable<any> {
      const p = this.client.getEntries({
        'fields.phoneId': phoneId,
        'content_type': contentId
      });
      return from(p)
        .pipe(
          map(entry => entry.items)
      );
    }

    public getOneDefaultFinishesField(contentId, phoneId): Observable<any> {
      const p = this.client.getEntries({
        'fields.phoneId': phoneId,
        'content_type': contentId,
        // select: 'fields.finishId' ,
      });
      return from(p)
        .pipe(
          map(entry => entry.items[0].fields)
        );
    }
    public getOneDefaultCapacityField(contentId, phoneId): Observable<any> {
      const p = this.client.getEntries({
        'fields.phoneId': phoneId,
        'content_type': contentId,
        // select: 'fields.capacityId' ,
      });
      return from(p)
        .pipe(
          map(entry => entry.items[0].fields)
        );
    }
    public getPhoneImg(contentId, finishId): Observable<any> {
      const p = this.client.getEntries({
        'fields.finishId': finishId,
        'content_type': contentId
      });
      return from(p)
        .pipe(
          map(entry => entry.items[0].fields)
        );
    }
    public getPhoneCapacity(contentId, capacityId): Observable<any> {
      const p = this.client.getEntries({
        'fields.capacityId': capacityId,
        'content_type': contentId
      });
      return from(p)
        .pipe(
          map(entry => entry.items[0].fields)
        );
    }
}
