<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <img src="assets/img/security.svg" height="100" width="100" alt="security-icon">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML" class="inner"></div>
        <p class="timer"><b>{{minutes}} : {{seconds}}</b> minutes remaining</p>

        <div class="modal-actions">
          <button class="button primary" (click)="OK()" id="action-button"
            data-cy="action-button">{{context.primaryBtn}}</button>
          <button class="button secondary" (click)="cancel()" id="action-button"
            data-cy="action-button">{{context.secondaryBtn}}</button>
        </div>
      </div>
    </div>
  </div>
</div>