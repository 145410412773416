import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentfulService } from '@services/contentful.service';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-ild-countries',
  templateUrl: './ild-countries.component.html',
  styleUrls: ['./ild-countries.component.scss']
})
export class IldCountriesComponent implements OnInit, OnDestroy {
  public countries: Array<{country: string, unlimited: string}> = [];
  public transformedCountries: Array<{country: string, minutes: Array<{key: string, value: string}>}> = [];
  public filteredCountries: Array<{country: string, minutes: Array<{key: string, value: string}>}> = [];
  
  private alive = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialog: MatDialogRef<any>,
              private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    this.contentfulService.getContent('destinations').pipe(takeWhile(() => this.alive)).subscribe((data) => {
      if (!!data) {
        data?.forEach(element => {
          this.countries.push({ country: element?.fields?.country, unlimited: element?.fields?.unlimited });
        });

        this.countries = this.countries.sort((a, b) => a?.country?.localeCompare(b?.country));

        this.transformedCountries = this.countries.map(item => {
          const { country, unlimited } = item;
          let minutes = [];
          if (unlimited.includes('Landline')) {
            minutes.push({ key: 'Landline', value: 'Unlimited' });
          }
          if (unlimited.includes('Cellular')) {
            minutes.push({ key: 'Cellular', value: 'Unlimited' });
          } else {
            minutes.push({ key: 'Cellular', value: '---' });
          }
          return { country, minutes };
        });

        this.filteredCountries = [...this.transformedCountries];
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  public search(text: string) {
    if (text) {
      // Filter transformedCountries based on user input
      this.filteredCountries = this.transformedCountries.filter(elm => 
        elm.country.toLowerCase().includes(text.trim().toLowerCase()));
    } else {
      // If search input is cleared, show all countries
      this.filteredCountries = [...this.transformedCountries];
    }
  }

  closeDialog(res?): void {
    this.dialog.close(res);
  }
}
