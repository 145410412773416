<div class="instruction-box">
    <div class="box-header">
        <div class="close-btn" (click)="closeDialog()">
            <img src="assets/icon/popup-close-icon.svg" class="close-icon" width="32" height="32" alt="Close Icon">
        </div>
        <h5 class="box-title">How to download your iPhone LTE settings</h5>
    </div>
    <div class="box-body" [ngSwitch]="activeStep" >
        <ng-container *ngFor="let contentItem of content;let i = index;">
            <div *ngSwitchCase="i" [innerHTML]="content[i]"> </div>
        </ng-container>
    </div>
    <div class="box-footer">
        <button class="button secondary" (click)="back()" *ngIf="this.activeStep > 0">Back</button>
        <button class="button primary" (click)="next()" *ngIf="activeStep < content.length-1">Next</button>
        <button class="button primary" (click)="closeDialog()" *ngIf="activeStep == content.length-1">Done</button>
    </div>
</div>