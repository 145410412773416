import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-instractions-five-g-ios17-modal',
    templateUrl: './instractions-five-g-ios17-modal.component.html',
    styleUrls: ['./instractions-five-g-ios17-modal.component.scss']
})

export class InstractionsFiveGIos17ModalComponent {

    constructor(
        private dialog: MatDialogRef<InstractionsFiveGIos17ModalComponent>) {

        this.activeStep = 1;
    }

    activeStep: number;

    next() {
        if (this.activeStep == 4)
            this.dialog.close();
        ++this.activeStep;
    }

    back() {
        if (this.activeStep == 1)
            return;
        --this.activeStep;
    }

    close() {
        this.dialog.close();
    }
}