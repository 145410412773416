import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class IosDataSetupModalContext {
    public network: string;
}

@Component({
    selector: 'ios-data-setup-modal',
    templateUrl: './ios-data-setup-modal.component.html',
    styleUrls: ['./ios-data-setup-modal.component.scss']
})
export class IosDataSetupModalComponent implements OnInit {
    public context: any;
    public activeStep: number;
    public content;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialogRef<IosDataSetupModalContext>) {
        this.context = data;
        this.activeStep = 0;

        this.content = [
            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">1- Click on the <b>‘${this.context.network === '5G' ? 'iPhone 5G - eRESELLER' : 'iPhone 4G - RESELLER'} APN FILE’</b> button as highlighted below.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step1-${this.context.network.toUpperCase()}.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">2. After approving the configuration profile to download, a file will be downloaded to your iPhone device. </p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step2-${this.context.network.toUpperCase()}.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">3. Go to your iPhone’s Settings and Click on <b>‘General’</b>. </p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step3.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">4. Go to <b>‘Profile’</b> from your General Settings. </p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step4.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">5. Next, click on the FreeUp LTE Data Settings tab to configure your iPhone LTE settings.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step5.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">6. Click on <b>‘Install’</b> to install the LTE iPhone configuration settings on your device.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step6.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">7. You will be asked for your device password.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step7.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">8. Click <b>‘Install’</b> on the top right.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step8.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">9. Click <b>‘Install’</b> again to confirm.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step9.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">10. You’re done! The iPhone LTE Data Settings have been successfully downloaded to your device, you can now enjoy your FreeUp service.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step10.svg">
                </div>
            </div>`,

            `<div class="flex-phone-display">
                <div class="steps">
                    <p class="text-content">11. Your ${this.context.network.toUpperCase()} will be enabled successfully on your account summary.</p>
                </div>
                <div>
                    <img class="step-image" src="assets/img/step11-${this.context.network.toUpperCase()}.svg">
                </div>
            </div>`,
        ];
    }


    ngOnInit(): void {
    }

    next() {
        if (this.activeStep >= this.content.length - 1) {
            return;
        }
        ++this.activeStep;
    }

    back() {
        if (this.activeStep == 0)
            return;
        --this.activeStep;
    }

    beforeClose(): boolean {
        if (document.body.classList.contains('modal-open')) {
            document.body.classList.remove('modal-open');
        }
        return false;
    }
    beforeDismiss(): boolean {
        return this.beforeClose();
    }

    closeDialog(res?): void {
        this.beforeDismiss();
        this.dialog.close(res);
    }
}