<div class="modal">
    <div class="container model-container {{context.customClass}}">
        <div class="modal-header">
            <div class="success-image">
                <img src="/assets/icon/modal-success-image.svg" alt="Success Icon">
            </div>
            <h2 class="header" *ngIf="context?.title">{{context.title}}</h2>
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="row">
                    <p class="success-message">
                        {{context.message}}
                    </p>
                </div>
                <div class="row modal-actions">
                    <button class="button primary" (click)="done()">{{context.buttonText}}</button>
                </div>
            </div>
        </div>
    </div>
</div>