import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { SwiperModule } from 'swiper/angular';
import { HeaderMainNavbarComponent } from './header-main-navbar/header-main-navbar.component';
import { FooterMainNavbarComponent } from './footer-main-navbar/footer-main-navbar.component';
import { SocialMediaSwiperComponent } from './social-media-swiper/social-media-swiper.component';
import { ReCaptchaComponent } from './re-captcha/re-captcha.component';
import { ActivatedPlanSelectorComponent } from './activated-plan-selector/activated-plan-selector.component';
import { UiBlockButtonDirective } from './directives/ui-block-button.directive';
import { PhonePipe } from './pipes/phone.pipe';
import { MobileFormatDataPipe } from './pipes/mobile-format-data.pipe';
import { ExpirationDateFormatPipe } from './pipes/expiration-date-format.pipe';
import { SimpleDateFormatPipe } from './pipes/simple-date-format.pipe';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { InvisibleRecaptchaComponent } from './invisible-recaptcha/invisible-recaptcha.component';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { CreditCardPaymentComponent } from './credit-card-payment/credit-card-payment.component';
import { CreditCardFormatDirective } from './directives/credit-card-format.directive';
import { ModalHelper } from '../services/modal-helper.service';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { RestrictNumbersDirective } from './directives/restrict-numbers.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { CoverageMapComponent } from './coverage-map/coverage-map.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [
    HeaderMainNavbarComponent,
    FooterMainNavbarComponent,
    SocialMediaSwiperComponent,
    ReCaptchaComponent,
    ActivatedPlanSelectorComponent,
    PhonePipe,
    MobileFormatDataPipe,
    UiBlockButtonDirective,
    ExpirationDateFormatPipe,
    CustomPaginationComponent,
    AddressLookupComponent,
    CreditCardPaymentComponent,
    CreditCardFormatDirective,
    InvisibleRecaptchaComponent,
    SimpleDateFormatPipe,
    SafePipe,
    QuestionAnswerComponent,
    RestrictNumbersDirective,
    PhoneMaskDirective,
    CoverageMapComponent],
  imports: [
    CommonModule,
    SwiperModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    NgxPaginationModule,
    RouterModule,
    NgxPaginationModule
  ],
  exports: [
    HeaderMainNavbarComponent,
    FooterMainNavbarComponent,
    SocialMediaSwiperComponent,
    ReCaptchaComponent,
    ActivatedPlanSelectorComponent,
    CustomPaginationComponent,
    InvisibleRecaptchaComponent,
    AddressLookupComponent,
    CreditCardPaymentComponent,
    QuestionAnswerComponent,
    CoverageMapComponent,
    CreditCardFormatDirective,
    UiBlockButtonDirective,
    RestrictNumbersDirective,
    PhoneMaskDirective,
    PhonePipe,
    MobileFormatDataPipe,
    ExpirationDateFormatPipe,
    SimpleDateFormatPipe,
    SafePipe
  ],
  providers: [SimpleDateFormatPipe, ModalHelper, SafePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WidgetsModule { }
